import {createSlice} from '@reduxjs/toolkit'

interface State {
    sidebarShow: 'responsive' | boolean;
    asideShow: boolean;
    darkMode: boolean;
}

const initialState: State = {
    sidebarShow: 'responsive',
    asideShow: false,
    darkMode: true,
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,

    reducers: {
        setSidebarShow: (state: State, action) => {
            state.sidebarShow = action.payload;
        },
        setAsideShow: (state: State, action) => {
            state.asideShow = action.payload;
        },
        setDarkMode: (state: State, action) => {
            state.darkMode = action.payload;
        },
    },

})

// Action creators are generated for each case reducer function
export const {setSidebarShow, setAsideShow, setDarkMode} = commonSlice.actions

export default commonSlice.reducer
