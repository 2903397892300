import { AuthenticationResponse } from '../../gen/endurosat-api'
import { clearBrowserStorageApiCache } from '../../utility/browser-storage-manipulations'
import LocalStorage from './local-storage.service'
class AuthLocalStorageService {
    setAuthData = (data: AuthenticationResponse) => {
        LocalStorage.setItem('authData', JSON.stringify(data))
    }

    getAuthData = (): AuthenticationResponse | undefined => {
        const authData = LocalStorage.getItem('authData')
        if (authData) {
            return JSON.parse(authData)
        }
    }

    removeAuthData = () => {
        LocalStorage.removeItem('authData')
        clearBrowserStorageApiCache()
    }
}

export default new AuthLocalStorageService()
