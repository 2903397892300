import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {icons} from './assets/icons'
import {Provider} from 'react-redux'
import store from './store/index'
import registerAxiosAuthHeaders from './interceptors/auth.interceptor'

React.icons = icons

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
)
serviceWorker.unregister()
registerAxiosAuthHeaders()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
