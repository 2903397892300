/* tslint:disable */
/* eslint-disable */
/**
 * EnduroSat SpaceOps REST API
 * Documentation for EnduroSat SpaceOps REST API
 *
 * The version of the OpenAPI document: 0.34.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * ADCS Config for this command
 * @export
 * @interface ADCSConfig
 */
export interface ADCSConfig {
    /**
     * ADCS Control Mode
     * @type {string}
     * @memberof ADCSConfig
     */
    controlMode?: ADCSConfigControlModeEnum;
    /**
     * Ground Target Geographic Longitude
     * @type {number}
     * @memberof ADCSConfig
     */
    geoLongitude?: number;
    /**
     * Ground Target Geographic Latitude
     * @type {number}
     * @memberof ADCSConfig
     */
    geoLatitude?: number;
    /**
     * Ground Target Geographic Elevation
     * @type {number}
     * @memberof ADCSConfig
     */
    geoAltitude?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ADCSConfigControlModeEnum {
    PWR_UP = 'PWR_UP',
    NORMAL_DETUMBLING = 'NORMAL_DETUMBLING',
    Y_THOMSON = 'Y_THOMSON',
    Y_THOMSON_MEMS_RATE = 'Y_THOMSON_MEMS_RATE',
    FAST_DETUMBLING = 'FAST_DETUMBLING',
    VERY_FAST_DETUMBLING = 'VERY_FAST_DETUMBLING',
    Y_MOMENTUM = 'Y_MOMENTUM',
    Y_MOMENTUM_FULL_STATE_EKF = 'Y_MOMENTUM_FULL_STATE_EKF',
    _3AXIS = '_3AXIS',
    SUN_TRACKING = 'SUN_TRACKING',
    TARGET_TRACKING = 'TARGET_TRACKING',
    NO_CONTROL = 'NO_CONTROL',
    ATTITUDE_CHANGE = 'ATTITUDE_CHANGE',
    ORBITAL_CHANGE = 'ORBITAL_CHANGE',
    XBAND_TARGET_TRACKING = 'XBAND_TARGET_TRACKING'
}

/**
 * 
 * @export
 * @interface AccessControlPolicy
 */
export interface AccessControlPolicy {
    /**
     * 
     * @type {string}
     * @memberof AccessControlPolicy
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccessControlPolicy
     */
    actions?: Array<string>;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof AccessControlPolicy
     */
    resources?: Array<{ [key: string]: string; }>;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof AccessControlPolicy
     */
    principals?: Array<{ [key: string]: string; }>;
}
/**
 * Possible artifacts of S3 resources
 * @export
 * @enum {string}
 */

export enum ArtifactType {
    LOG = 'LOG',
    WATERFALL_RX = 'WATERFALL_RX',
    WATERFALL_RX_CORRECTED = 'WATERFALL_RX_CORRECTED',
    WATERFALL_TX = 'WATERFALL_TX',
    METRICS = 'METRICS',
    PAYLOAD = 'PAYLOAD'
}

/**
 * 
 * @export
 * @interface AuthenticationResponse
 */
export interface AuthenticationResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    access_token?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticationResponse
     */
    expires_in?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    token_type?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    refresh_token?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationResponse
     */
    id_token?: string;
    /**
     * 
     * @type {NewDeviceMetadataType}
     * @memberof AuthenticationResponse
     */
    new_device_metadata?: NewDeviceMetadataType;
}
/**
 * 
 * @export
 * @interface BaseConjunctionData
 */
export interface BaseConjunctionData {
    /**
     * 
     * @type {string}
     * @memberof BaseConjunctionData
     */
    tca?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    missDistance?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    relativePositionR?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    relativePositionT?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    relativePositionN?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    relativeSpeed?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    relativeVelocityR?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    relativeVelocityT?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    relativeVelocityN?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    apparentIncidenceAngle?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    eventAltitude?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    combinedHardBodyRadius?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseConjunctionData
     */
    collisionProbability?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseConjunctionData
     */
    collisionProbabilityMethod?: BaseConjunctionDataCollisionProbabilityMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseConjunctionData
     */
    riskLevel?: BaseConjunctionDataRiskLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseConjunctionData
     */
    screeningType?: BaseConjunctionDataScreeningTypeEnum;
    /**
     * 
     * @type {ScaledCollisionProbability}
     * @memberof BaseConjunctionData
     */
    maxScaledCollisionProbability?: ScaledCollisionProbability;
    /**
     * 
     * @type {boolean}
     * @memberof BaseConjunctionData
     */
    isInDilutionZone?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum BaseConjunctionDataCollisionProbabilityMethodEnum {
    AKELLAALFRIEND_2000 = 'AKELLAALFRIEND_2000',
    ALFANO_2005 = 'ALFANO_2005',
    ALFANO_MAX_PROBABILITY = 'ALFANO_MAX_PROBABILITY',
    ALFANO_PARAL_2007 = 'ALFANO_PARAL_2007',
    ALFANO_TUBES_2007 = 'ALFANO_TUBES_2007',
    ALFANO_VOXELS_2006 = 'ALFANO_VOXELS_2006',
    ALFRIEND_1999 = 'ALFRIEND_1999',
    CHAN_1997 = 'CHAN_1997',
    CHAN_2003 = 'CHAN_2003',
    FOSTER_1992 = 'FOSTER_1992',
    MCKINLEY_2006 = 'MCKINLEY_2006',
    PATERA_2001 = 'PATERA_2001',
    PATERA_2003 = 'PATERA_2003',
    PATERA_2005 = 'PATERA_2005',
    COPPOLA_2012 = 'COPPOLA_2012',
    COPOC = 'COPOC',
    ELROD = 'ELROD',
    OTHER = 'OTHER'
}
/**
    * @export
    * @enum {string}
    */
export enum BaseConjunctionDataRiskLevelEnum {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    CRITICAL = 'CRITICAL',
    UNKNOWN = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum BaseConjunctionDataScreeningTypeEnum {
    CAT_VS_CAT = 'CAT_VS_CAT',
    CAT_VS_CDM = 'CAT_VS_CDM',
    CAT_VS_OPS = 'CAT_VS_OPS',
    CAT_VS_TLE = 'CAT_VS_TLE',
    CDM_VS_CAT = 'CDM_VS_CAT',
    CDM_VS_CDM = 'CDM_VS_CDM',
    CDM_VS_OPS = 'CDM_VS_OPS',
    CDM_VS_TLE = 'CDM_VS_TLE',
    OPS_VS_CAT = 'OPS_VS_CAT',
    OPS_VS_CDM = 'OPS_VS_CDM',
    OPS_VS_OPS = 'OPS_VS_OPS',
    OPS_VS_TLE = 'OPS_VS_TLE',
    TLE_VS_CAT = 'TLE_VS_CAT',
    TLE_VS_CDM = 'TLE_VS_CDM',
    TLE_VS_OPS = 'TLE_VS_OPS',
    TLE_VS_TLE = 'TLE_VS_TLE',
    MAN_VS_CDM = 'MAN_VS_CDM',
    UNKNOWN = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface CatalogueObjectWithKeplerianElements
 */
export interface CatalogueObjectWithKeplerianElements {
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    catalogueId?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    catalogueName?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    objectName?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    cosparId?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    objectType?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    manoeuvrable?: CatalogueObjectWithKeplerianElementsManoeuvrableEnum;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    hardBodyRadius?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    orbitalPeriod?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    perigeeAltitude?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    apogeeAltitude?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    semiMajorAxis?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    eccentricity?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    inclination?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    raOfAscNode?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    argOfPericenter?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    trueAnomaly?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogueObjectWithKeplerianElements
     */
    argOfLatitude?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CatalogueObjectWithKeplerianElementsManoeuvrableEnum {
    YES = 'YES',
    NO = 'NO',
    N_A = 'N/A'
}

/**
 * Communication Channel Configuration as supported by EnduroSat
 * @export
 * @interface ChannelConfiguration
 */
export interface ChannelConfiguration {
    /**
     * Communication Channel Name as supported by EnduroSat
     * @type {string}
     * @memberof ChannelConfiguration
     */
    name?: string;
    /**
     * Communication Stream as supported by EnduroSat
     * @type {string}
     * @memberof ChannelConfiguration
     */
    stream?: string;
    /**
     * Communication Channel Configuration Properties as supported by EnduroSat
     * @type {{ [key: string]: string; }}
     * @memberof ChannelConfiguration
     */
    configuration?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface CombinedAccessControlPolicy
 */
export interface CombinedAccessControlPolicy {
    /**
     * 
     * @type {string}
     * @memberof CombinedAccessControlPolicy
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedAccessControlPolicy
     */
    description?: string;
    /**
     * 
     * @type {Array<AccessControlPolicy>}
     * @memberof CombinedAccessControlPolicy
     */
    policies?: Array<AccessControlPolicy>;
}
/**
 * 
 * @export
 * @interface CombinedAccessControlPolicyRequestDTO
 */
export interface CombinedAccessControlPolicyRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof CombinedAccessControlPolicyRequestDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CombinedAccessControlPolicyRequestDTO
     */
    description?: string;
    /**
     * 
     * @type {Array<AccessControlPolicy>}
     * @memberof CombinedAccessControlPolicyRequestDTO
     */
    policies?: Array<AccessControlPolicy>;
}
/**
 * Configuration of the telecommand
 * @export
 * @interface CommandConfig
 */
export interface CommandConfig {
    /**
     * macInterfaceID of the telecommand
     * @type {number}
     * @memberof CommandConfig
     */
    macInterfaceID: number;
    /**
     * targetAddress of the telecommand
     * @type {number}
     * @memberof CommandConfig
     */
    targetAddress: number;
    /**
     * priority of the telecommand
     * @type {number}
     * @memberof CommandConfig
     */
    priority: number;
    /**
     * timeout of the telecommand
     * @type {number}
     * @memberof CommandConfig
     */
    timeout: number;
    /**
     * commandBytes of the telecommand
     * @type {string}
     * @memberof CommandConfig
     */
    commandBytes: string;
}
/**
 * Communication Channel Descriptions as supported by EnduroSat
 * @export
 * @interface CommunicationChannel
 */
export interface CommunicationChannel {
    /**
     * Channel ID as supported by EnduroSat
     * @type {string}
     * @memberof CommunicationChannel
     */
    id: string;
    /**
     * Antenna ID as supported by EnduroSat
     * @type {string}
     * @memberof CommunicationChannel
     */
    antenna: string;
    /**
     * Communcation Channel Direction - UPLINK | DOWNLINK
     * @type {string}
     * @memberof CommunicationChannel
     */
    direction: CommunicationChannelDirectionEnum;
    /**
     * Frequency Band - UHF | SBAND | XBAND
     * @type {string}
     * @memberof CommunicationChannel
     */
    frequencyBand: CommunicationChannelFrequencyBandEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CommunicationChannelDirectionEnum {
    UPLINK = 'UPLINK',
    DOWNLINK = 'DOWNLINK'
}
/**
    * @export
    * @enum {string}
    */
export enum CommunicationChannelFrequencyBandEnum {
    UHF = 'UHF',
    SBAND = 'SBAND',
    XBAND = 'XBAND'
}

/**
 * Communication Channel Protocols as supported by EnduroSat
 * @export
 * @interface CommunicationProtocol
 */
export interface CommunicationProtocol {
    /**
     * Type of the communication protocol
     * @type {string}
     * @memberof CommunicationProtocol
     */
    type?: string;
    /**
     * Describes streams between satellites and the ground station
     * @type {{ [key: string]: string; }}
     * @memberof CommunicationProtocol
     */
    satelliteStreams?: { [key: string]: string; };
    /**
     * Describes streams between the ground station and the Cloud layer
     * @type {{ [key: string]: string; }}
     * @memberof CommunicationProtocol
     */
    userStreams?: { [key: string]: string; };
    /**
     * Antenna Configuration Properties as supported by EnduroSat
     * @type {{ [key: string]: string; }}
     * @memberof CommunicationProtocol
     */
    configuration?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ConjunctionEvent
 */
export interface ConjunctionEvent {
    /**
     * 
     * @type {string}
     * @memberof ConjunctionEvent
     */
    conjunctionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConjunctionEvent
     */
    satelliteId?: string;
    /**
     * 
     * @type {BaseConjunctionData}
     * @memberof ConjunctionEvent
     */
    conjunctionData?: BaseConjunctionData;
    /**
     * 
     * @type {CatalogueObjectWithKeplerianElements}
     * @memberof ConjunctionEvent
     */
    object1?: CatalogueObjectWithKeplerianElements;
    /**
     * 
     * @type {CatalogueObjectWithKeplerianElements}
     * @memberof ConjunctionEvent
     */
    object2?: CatalogueObjectWithKeplerianElements;
    /**
     * 
     * @type {string}
     * @memberof ConjunctionEvent
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ConjunctionEvent
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ConjunctionEvent
     */
    lastScreenedAt?: string;
}
/**
 * The event subscription object
 * @export
 * @interface CreateEventSubscriptionRequest
 */
export interface CreateEventSubscriptionRequest {
    /**
     * The Id of the event-type to which the user is subscribed
     * @type {string}
     * @memberof CreateEventSubscriptionRequest
     */
    eventType: string;
    /**
     * The filters to be applied to the event
     * @type {Array<EventFilter>}
     * @memberof CreateEventSubscriptionRequest
     */
    eventFilters: Array<EventFilter>;
    /**
     * 
     * @type {EventAction}
     * @memberof CreateEventSubscriptionRequest
     */
    action?: EventAction;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * Error Description
     * @type {string}
     * @memberof ErrorResponse
     */
    error?: string;
}
/**
 * The action to be taken when the event is triggered
 * @export
 * @interface EventAction
 */
export interface EventAction {
    /**
     * Type of action to be performed
     * @type {string}
     * @memberof EventAction
     */
    type: string;
    /**
     * Configuration of the action
     * @type {object}
     * @memberof EventAction
     */
    configuration: object;
}
/**
 * The filters to be applied to the event
 * @export
 * @interface EventFilter
 */
export interface EventFilter {
    /**
     * The property of the event to be filtered
     * @type {string}
     * @memberof EventFilter
     */
    property: string;
    /**
     * The operator to be used for filtering
     * @type {string}
     * @memberof EventFilter
     */
    operator: string;
    /**
     * The value to be used for filtering
     * @type {object}
     * @memberof EventFilter
     */
    value: object;
}
/**
 * 
 * @export
 * @interface EventPayload
 */
export interface EventPayload {
    /**
     * Configuration of the action
     * @type {object}
     * @memberof EventPayload
     */
    data: object;
    /**
     * Event Type
     * @type {string}
     * @memberof EventPayload
     */
    type: string;
    /**
     * Event Source
     * @type {string}
     * @memberof EventPayload
     */
    source: string;
}
/**
 * The event subscription object
 * @export
 * @interface EventSubscription
 */
export interface EventSubscription {
    /**
     * The Id of the event subscription
     * @type {string}
     * @memberof EventSubscription
     */
    id: string;
    /**
     * The Id of the event-type to which the user is subscribed
     * @type {string}
     * @memberof EventSubscription
     */
    eventType: string;
    /**
     * The filters to be applied to the event
     * @type {Array<EventFilter>}
     * @memberof EventSubscription
     */
    eventFilters: Array<EventFilter>;
    /**
     * 
     * @type {EventAction}
     * @memberof EventSubscription
     */
    action: EventAction;
    /**
     * The Id of the user who created the event subscription
     * @type {string}
     * @memberof EventSubscription
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface EventSubscriptionsResultPage
 */
export interface EventSubscriptionsResultPage {
    /**
     * Last evaluated item. Used for pagination.
     * @type {string}
     * @memberof EventSubscriptionsResultPage
     */
    lastEvaluatedItem?: string;
    /**
     * The result data set, contained in the current page
     * @type {Array<EventSubscription>}
     * @memberof EventSubscriptionsResultPage
     */
    items?: Array<EventSubscription>;
}
/**
 * 
 * @export
 * @interface EventType
 */
export interface EventType {
    /**
     * Object ID
     * @type {string}
     * @memberof EventType
     */
    id?: string;
}
/**
 * Execution type
 * @export
 * @interface ExecutionRule
 */
export interface ExecutionRule {
    /**
     * Execution Rule type
     * @type {string}
     * @memberof ExecutionRule
     */
    executionRuleType?: ExecutionRuleExecutionRuleTypeEnum;
    /**
     * Execution Rule properties
     * @type {{ [key: string]: string; }}
     * @memberof ExecutionRule
     */
    properties?: { [key: string]: string; };
}

/**
    * @export
    * @enum {string}
    */
export enum ExecutionRuleExecutionRuleTypeEnum {
    FIRST_OPPORTUNITY = 'FIRST_OPPORTUNITY',
    SPECIFIC_PASS = 'SPECIFIC_PASS',
    SPECIFIC_GROUND_STATION = 'SPECIFIC_GROUND_STATION',
    EVERY_PASS = 'EVERY_PASS',
    EVERY_N_DAYS = 'EVERY_N_DAYS',
    CONDITIONAL = 'CONDITIONAL'
}

/**
 * 
 * @export
 * @interface GroundStation
 */
export interface GroundStation {
    /**
     * Object ID
     * @type {string}
     * @memberof GroundStation
     */
    id?: string;
    /**
     * Ground Station Geographic Longitude
     * @type {number}
     * @memberof GroundStation
     */
    longitude?: number;
    /**
     * Ground Station Geographic Latitude
     * @type {number}
     * @memberof GroundStation
     */
    latitude?: number;
    /**
     * Ground Station Geographic Elevation
     * @type {number}
     * @memberof GroundStation
     */
    elevation?: number;
    /**
     * Antenna Descriptions as supported by EnduroSat
     * @type {{ [key: string]: TypedDescriptor; }}
     * @memberof GroundStation
     */
    antennas?: { [key: string]: TypedDescriptor; };
    /**
     * Communication Channel Descriptions as supported by EnduroSat
     * @type {Set<CommunicationChannel>}
     * @memberof GroundStation
     */
    channels?: Set<CommunicationChannel>;
    /**
     * Communication Channel Protocols as supported by EnduroSat
     * @type {Set<string>}
     * @memberof GroundStation
     */
    supportedProtocols?: Set<string>;
    /**
     * Communication Channel Protocols as supported by EnduroSat
     * @type {{ [key: string]: CommunicationProtocol; }}
     * @memberof GroundStation
     */
    protocols?: { [key: string]: CommunicationProtocol; };
    /**
     * Represent the endpoints of Streams as supported by EnduroSat
     * @type {{ [key: string]: TypedDescriptor; }}
     * @memberof GroundStation
     */
    sockets?: { [key: string]: TypedDescriptor; };
    /**
     * Describes streams between satellites and the ground station
     * @type {{ [key: string]: { [key: string]: StreamDescriptor; }; }}
     * @memberof GroundStation
     */
    satelliteStreams?: { [key: string]: { [key: string]: StreamDescriptor; }; };
    /**
     * Describes streams between the ground station and the Cloud layer
     * @type {{ [key: string]: UserStreamDescriptor; }}
     * @memberof GroundStation
     */
    userStreams?: { [key: string]: UserStreamDescriptor; };
    /**
     * Describes the available hardware on the radio layer
     * @type {{ [key: string]: TypedDescriptor; }}
     * @memberof GroundStation
     */
    hardware?: { [key: string]: TypedDescriptor; };
    /**
     * Additional Properties for the configuration of the Ground Station
     * @type {{ [key: string]: string; }}
     * @memberof GroundStation
     */
    additionalProperties?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface MissionDatabase
 */
export interface MissionDatabase {
    /**
     * Object ID
     * @type {string}
     * @memberof MissionDatabase
     */
    id?: string;
    /**
     * Name of the Mission Database
     * @type {string}
     * @memberof MissionDatabase
     */
    name?: string;
    /**
     * Type of the Mission Database
     * @type {string}
     * @memberof MissionDatabase
     */
    type?: string;
    /**
     * IDs of the Satellites for which the Mission Database is valid.
     * @type {Array<string>}
     * @memberof MissionDatabase
     */
    satellites?: Array<string>;
    /**
     * IDs of the Satellite Subsystems for which the Mission Database is valid.
     * @type {Array<string>}
     * @memberof MissionDatabase
     */
    satelliteSubsystems?: Array<string>;
    /**
     * Key-Value Configuration Properties
     * @type {{ [key: string]: string; }}
     * @memberof MissionDatabase
     */
    configuration?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface MissionDatabasePackage
 */
export interface MissionDatabasePackage {
    /**
     * Object ID
     * @type {string}
     * @memberof MissionDatabasePackage
     */
    id?: string;
    /**
     * List of the mission databases associated with this pacakge
     * @type {Array<string>}
     * @memberof MissionDatabasePackage
     */
    missionDatabases?: Array<string>;
}
/**
 * 
 * @export
 * @interface MissionDatabasesResultPage
 */
export interface MissionDatabasesResultPage {
    /**
     * Last evaluated item. Used for pagination.
     * @type {string}
     * @memberof MissionDatabasesResultPage
     */
    lastEvaluatedItem?: string;
    /**
     * The result data set, contained in the current page
     * @type {Array<MissionDatabase>}
     * @memberof MissionDatabasesResultPage
     */
    items?: Array<MissionDatabase>;
}
/**
 * 
 * @export
 * @interface NewDeviceMetadataType
 */
export interface NewDeviceMetadataType {
    /**
     * 
     * @type {string}
     * @memberof NewDeviceMetadataType
     */
    deviceKey?: string;
    /**
     * 
     * @type {string}
     * @memberof NewDeviceMetadataType
     */
    deviceGroupKey?: string;
}
/**
 * Orbit configuration for the simulation
 * @export
 * @interface OrbitConfig
 */
export interface OrbitConfig {
    /**
     * Time and date of start simulation. Format: [Y, M, D, H, M, S]
     * @type {Array<number>}
     * @memberof OrbitConfig
     */
    startTime?: Array<number>;
    /**
     * Time since last perigee passage [s]
     * @type {number}
     * @memberof OrbitConfig
     */
    tp?: number;
    /**
     * Satellite altitude [km]
     * @type {number}
     * @memberof OrbitConfig
     */
    sma?: number;
    /**
     * Eccentricity
     * @type {number}
     * @memberof OrbitConfig
     */
    eccentricity?: number;
    /**
     * Inclination [deg]
     * @type {number}
     * @memberof OrbitConfig
     */
    inclination?: number;
    /**
     * Argument of Periapsis [deg]
     * @type {number}
     * @memberof OrbitConfig
     */
    aop?: number;
    /**
     * Right ascension of a.n. [deg]. (Either choose RAAN or LTAN input, if use one, set the other to null)
     * @type {number}
     * @memberof OrbitConfig
     */
    raan?: number;
    /**
     * Float (e.g. 13.5 = 13:30). (Either choose RAAN or LTAN input, if use one, set the other to null)
     * @type {number}
     * @memberof OrbitConfig
     */
    ltan?: number;
    /**
     * True anomaly difference between two satellites if intersat pointing [deg]
     * @type {number}
     * @memberof OrbitConfig
     */
    separationAngle?: number;
    /**
     * Altitude difference between the two sats, negative difference means satellite 2 is lower [km]
     * @type {number}
     * @memberof OrbitConfig
     */
    altitudeDifference?: number;
    /**
     * Position knowledge accuracy [Rmean, Rsigma, Tmean, Tsigma, Nmean, Nsigma] Radial Tangent Normal [14, 95, 1, 547, 4, 153] [m]
     * @type {Array<number>}
     * @memberof OrbitConfig
     */
    positionErrorRTN?: Array<number>;
}
/**
 * 
 * @export
 * @interface PassPrediction
 */
export interface PassPrediction {
    /**
     * Object ID
     * @type {string}
     * @memberof PassPrediction
     */
    id?: string;
    /**
     * Start time of multipart upload in progress
     * @type {number}
     * @memberof PassPrediction
     */
    fileUploadStart?: number;
    /**
     * Specifies if this resource has an uploaded payload file
     * @type {boolean}
     * @memberof PassPrediction
     */
    hasFile?: boolean;
    /**
     * Status of the Satellite Prediction - Will always be PREDICTION
     * @type {string}
     * @memberof PassPrediction
     */
    status: PassPredictionStatusEnum;
    /**
     * ID of the Satellite for this Pass
     * @type {string}
     * @memberof PassPrediction
     */
    satellite: string;
    /**
     * ID of the Ground Station above which the satellite will pass
     * @type {string}
     * @memberof PassPrediction
     */
    groundStation: string;
    /**
     * Aquisition of Signal Time for the Satellite Pass
     * @type {number}
     * @memberof PassPrediction
     */
    aos: number;
    /**
     * Loss of Signal Time for the Satellite Pass
     * @type {number}
     * @memberof PassPrediction
     */
    los: number;
    /**
     * Max Elevation in degrees of the Satellite Pass
     * @type {number}
     * @memberof PassPrediction
     */
    maxElevation: number;
    /**
     * TLE Line 1
     * @type {string}
     * @memberof PassPrediction
     */
    tle1?: string;
    /**
     * TLE Line 2
     * @type {string}
     * @memberof PassPrediction
     */
    tle2?: string;
    /**
     * Configuration of the action
     * @type {object}
     * @memberof PassPrediction
     */
    extraAttributes?: object;
    /**
     * Time until which the Pass Prediction is valid
     * @type {number}
     * @memberof PassPrediction
     */
    predictionTTL?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PassPredictionStatusEnum {
    PREDICTION = 'PREDICTION',
    SCHEDULED = 'SCHEDULED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED_SUCCESSFULLY = 'FINISHED_SUCCESSFULLY',
    FINISHED_WITH_ERRORS = 'FINISHED_WITH_ERRORS',
    CANCELED = 'CANCELED',
    MISSED = 'MISSED'
}

/**
 * 
 * @export
 * @interface PayloadSlot
 */
export interface PayloadSlot {
    /**
     * Object ID
     * @type {string}
     * @memberof PayloadSlot
     */
    id?: string;
    /**
     * Start Time of the Payload Slot
     * @type {number}
     * @memberof PayloadSlot
     */
    start: number;
    /**
     * End Time of the Payload Slot
     * @type {number}
     * @memberof PayloadSlot
     */
    end: number;
    /**
     * ID of the Satellite Subsystem for which the slot is applicable.
     * @type {string}
     * @memberof PayloadSlot
     */
    satelliteSubsystem: string;
}
/**
 * 
 * @export
 * @interface Satellite
 */
export interface Satellite {
    /**
     * Object ID
     * @type {string}
     * @memberof Satellite
     */
    id?: string;
    /**
     * Satellite Name
     * @type {string}
     * @memberof Satellite
     */
    name: string;
    /**
     * Satellite Catalog ID (NORAD ID)
     * @type {number}
     * @memberof Satellite
     */
    noradId?: number;
    /**
     * TLE Line 1
     * @type {string}
     * @memberof Satellite
     */
    tle1?: string;
    /**
     * TLE Line 2
     * @type {string}
     * @memberof Satellite
     */
    tle2?: string;
    /**
     * Satellite Protocol as supported by EnduroSat
     * @type {string}
     * @memberof Satellite
     */
    protocol: string;
    /**
     * Protocol Configuration Properties as supported by EnduroSat
     * @type {{ [key: string]: string; }}
     * @memberof Satellite
     */
    protocolConfiguration?: { [key: string]: string; };
    /**
     * Communication Channel Configuration as supported by EnduroSat
     * @type {Array<ChannelConfiguration>}
     * @memberof Satellite
     */
    channels: Array<ChannelConfiguration>;
}
/**
 * 
 * @export
 * @interface SatellitePass
 */
export interface SatellitePass {
    /**
     * Object ID
     * @type {string}
     * @memberof SatellitePass
     */
    id?: string;
    /**
     * Start time of multipart upload in progress
     * @type {number}
     * @memberof SatellitePass
     */
    fileUploadStart?: number;
    /**
     * Specifies if this resource has an uploaded payload file
     * @type {boolean}
     * @memberof SatellitePass
     */
    hasFile?: boolean;
    /**
     * Status of the Satellite Pass - SCHEDULED | IN_PROGRESS | FINISHED_SUCCESSFULLY | FINISHED_WITH_ERRORS | CANCELED
     * @type {string}
     * @memberof SatellitePass
     */
    status: SatellitePassStatusEnum;
    /**
     * ID of the Satellite for this Pass
     * @type {string}
     * @memberof SatellitePass
     */
    satellite: string;
    /**
     * ID of the Ground Station above which the satellite will pass
     * @type {string}
     * @memberof SatellitePass
     */
    groundStation: string;
    /**
     * Aquisition of Signal Time for the Satellite Pass
     * @type {number}
     * @memberof SatellitePass
     */
    aos: number;
    /**
     * Loss of Signal Time for the Satellite Pass
     * @type {number}
     * @memberof SatellitePass
     */
    los: number;
    /**
     * Max Elevation in degrees of the Satellite Pass
     * @type {number}
     * @memberof SatellitePass
     */
    maxElevation: number;
    /**
     * TLE Line 1
     * @type {string}
     * @memberof SatellitePass
     */
    tle1?: string;
    /**
     * TLE Line 2
     * @type {string}
     * @memberof SatellitePass
     */
    tle2?: string;
    /**
     * Configuration of the action
     * @type {object}
     * @memberof SatellitePass
     */
    extraAttributes?: object;
}

/**
    * @export
    * @enum {string}
    */
export enum SatellitePassStatusEnum {
    PREDICTION = 'PREDICTION',
    SCHEDULED = 'SCHEDULED',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED_SUCCESSFULLY = 'FINISHED_SUCCESSFULLY',
    FINISHED_WITH_ERRORS = 'FINISHED_WITH_ERRORS',
    CANCELED = 'CANCELED',
    MISSED = 'MISSED'
}

/**
 * 
 * @export
 * @interface SatellitePassesResultPage
 */
export interface SatellitePassesResultPage {
    /**
     * Last evaluated item. Used for pagination.
     * @type {string}
     * @memberof SatellitePassesResultPage
     */
    lastEvaluatedItem?: string;
    /**
     * The result data set, contained in the current page
     * @type {Array<SatellitePass>}
     * @memberof SatellitePassesResultPage
     */
    items?: Array<SatellitePass>;
}
/**
 * 
 * @export
 * @interface SatelliteSubsystem
 */
export interface SatelliteSubsystem {
    /**
     * Object ID
     * @type {string}
     * @memberof SatelliteSubsystem
     */
    id?: string;
    /**
     * Name of the subsystem
     * @type {string}
     * @memberof SatelliteSubsystem
     */
    name?: string;
    /**
     * ID of the satellite that the subsystem is part of
     * @type {string}
     * @memberof SatelliteSubsystem
     */
    satellite?: string;
    /**
     * Type of the subsystem
     * @type {string}
     * @memberof SatelliteSubsystem
     */
    type?: string;
    /**
     * Configuration Properties as supported by EnduroSat
     * @type {{ [key: string]: string; }}
     * @memberof SatelliteSubsystem
     */
    configuration?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ScaledCollisionProbability
 */
export interface ScaledCollisionProbability {
    /**
     * 
     * @type {number}
     * @memberof ScaledCollisionProbability
     */
    primaryFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof ScaledCollisionProbability
     */
    secondaryFactor?: number;
    /**
     * 
     * @type {number}
     * @memberof ScaledCollisionProbability
     */
    probabilityOfCollision?: number;
}
/**
 * 
 * @export
 * @interface SimulaitonResultPage
 */
export interface SimulaitonResultPage {
    /**
     * Last evaluated item. Used for pagination.
     * @type {string}
     * @memberof SimulaitonResultPage
     */
    lastEvaluatedItem?: string;
    /**
     * The result data set, contained in the current page
     * @type {Array<Simulation>}
     * @memberof SimulaitonResultPage
     */
    items?: Array<Simulation>;
}
/**
 * Response for the simulation results.
 * @export
 * @interface Simulation
 */
export interface Simulation {
    /**
     * The id of the simulation
     * @type {string}
     * @memberof Simulation
     */
    id?: string;
    /**
     * The status of the simulation
     * @type {string}
     * @memberof Simulation
     */
    status?: string;
    /**
     * The satellite id
     * @type {string}
     * @memberof Simulation
     */
    satelliteId?: string;
}
/**
 * Configuration for the simulation
 * @export
 * @interface SimulationConfig
 */
export interface SimulationConfig {
    /**
     * 1: ADCS, 2: STMA] (adcs only, OBC state machine)
     * @type {string}
     * @memberof SimulationConfig
     */
    simMode: SimulationConfigSimModeEnum;
    /**
     * Initial operational mode, can only be \'safe\' or \'idle\'. Only relevant for simmode 2.
     * @type {string}
     * @memberof SimulationConfig
     */
    initialMode?: SimulationConfigInitialModeEnum;
    /**
     * Maximum number of orbits, should be bigger or equal to the commanded time of the operational modes in OBC yaml [if not only adcs sim]
     * @type {number}
     * @memberof SimulationConfig
     */
    numberOfOrbits?: number;
    /**
     * [s] subsystems loop period
     * @type {number}
     * @memberof SimulationConfig
     */
    timeStep?: number;
    /**
     * [s] maximum integration timestep
     * @type {number}
     * @memberof SimulationConfig
     */
    maxIntegrationTimeStep?: number;
    /**
     * if True, no torques will be acting on the satellite (this setting is mainly for verification tests)
     * @type {boolean}
     * @memberof SimulationConfig
     */
    startWithTumbling?: boolean;
    /**
     * [deg/s] Initial rate in the body frame wrt ECI if starting with tumbling if startWithTumbling set to true.
     * @type {Array<number>}
     * @memberof SimulationConfig
     */
    initialRate?: Array<number>;
    /**
     * If true, no torques will be acting on the satellite
     * @type {boolean}
     * @memberof SimulationConfig
     */
    noTorques?: boolean;
    /**
     * If true, there will be no disturbance torques simulated
     * @type {boolean}
     * @memberof SimulationConfig
     */
    noDisturbTorques?: boolean;
    /**
     * Reaction wheel imbalance model [\'ADVANCED\', \'SIMPLE\', \'NOT\']
     * @type {string}
     * @memberof SimulationConfig
     */
    rwImbalanceModel?: SimulationConfigRwImbalanceModelEnum;
    /**
     * End the simulation if RWs are saturated; else, continue
     * @type {boolean}
     * @memberof SimulationConfig
     */
    stopOnRwSaturation?: boolean;
    /**
     * If True, the orientation of the sensors in the satellite will matter wrt sun earth blocking
     * @type {boolean}
     * @memberof SimulationConfig
     */
    includeSensorViews?: boolean;
    /**
     * If True, the Opsim will check the compatibility between control and estimation modes and transitions. if false, everything is allowed
     * @type {boolean}
     * @memberof SimulationConfig
     */
    includeEstCtrlCompatibility?: boolean;
    /**
     * Switch to nadir mode during eclipse, when sun-pointing and not SimMode ADCS
     * @type {boolean}
     * @memberof SimulationConfig
     */
    nadirModeInEclipse?: boolean;
    /**
     * [deg] when pointing error over sample period lower than this threshold after a mode transition, new mode is active and transition is completed
     * @type {number}
     * @memberof SimulationConfig
     */
    modeTransitionThreshold?: number;
    /**
     * number of samples for the above threshold (modeTransitionThreshold)
     * @type {number}
     * @memberof SimulationConfig
     */
    modeTransitionSamples?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigSimModeEnum {
    ADCS = 'ADCS',
    STMA = 'STMA'
}
/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigInitialModeEnum {
    SAFE = 'SAFE',
    IDLE = 'IDLE'
}
/**
    * @export
    * @enum {string}
    */
export enum SimulationConfigRwImbalanceModelEnum {
    ADVANCED = 'ADVANCED',
    SIMPLE = 'SIMPLE',
    NOT = 'NOT'
}

/**
 * Request to simulate a telecommand execution
 * @export
 * @interface SimulationRequest
 */
export interface SimulationRequest {
    /**
     * Id of the satellite
     * @type {string}
     * @memberof SimulationRequest
     */
    satelliteId: string;
    /**
     * List of telecommands to be simulated
     * @type {Array<TelecommandSimulationRequest>}
     * @memberof SimulationRequest
     */
    telecommands: Array<TelecommandSimulationRequest>;
    /**
     * 
     * @type {SimulationConfig}
     * @memberof SimulationRequest
     */
    simulationConfig?: SimulationConfig;
    /**
     * 
     * @type {OrbitConfig}
     * @memberof SimulationRequest
     */
    orbitConfig?: OrbitConfig;
}
/**
 * Details of the simulation result file.
 * @export
 * @interface SimulationResultFilesDetails
 */
export interface SimulationResultFilesDetails {
    /**
     * 
     * @type {SimulationResultFilesDetailsSimulationResultFiles}
     * @memberof SimulationResultFilesDetails
     */
    simulationResultFiles?: SimulationResultFilesDetailsSimulationResultFiles;
}
/**
 * Details of the simulation result file
 * @export
 * @interface SimulationResultFilesDetailsSimulationResultFiles
 */
export interface SimulationResultFilesDetailsSimulationResultFiles {
    /**
     * The name of the file
     * @type {string}
     * @memberof SimulationResultFilesDetailsSimulationResultFiles
     */
    fileName?: string;
    /**
     * The download url of the file
     * @type {string}
     * @memberof SimulationResultFilesDetailsSimulationResultFiles
     */
    fileDownloadUrl?: string;
}
/**
 * 
 * @export
 * @interface StreamDescriptor
 */
export interface StreamDescriptor {
    /**
     * Name of the socket
     * @type {string}
     * @memberof StreamDescriptor
     */
    socket?: string;
    /**
     * Type of the socket
     * @type {string}
     * @memberof StreamDescriptor
     */
    socketType?: StreamDescriptorSocketTypeEnum;
    /**
     * Stream Configuration Properties as supported by EnduroSat
     * @type {{ [key: string]: string; }}
     * @memberof StreamDescriptor
     */
    configuration?: { [key: string]: string; };
    /**
     * List of hardware that is needed to enable the stream to function
     * @type {Array<string>}
     * @memberof StreamDescriptor
     */
    hardware?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum StreamDescriptorSocketTypeEnum {
    IN = 'IN',
    OUT = 'OUT'
}

/**
 * The result data set, contained in the current page
 * @export
 * @interface Telecommand
 */
export interface Telecommand {
    /**
     * Object ID
     * @type {string}
     * @memberof Telecommand
     */
    id?: string;
    /**
     * Start time of multipart upload in progress
     * @type {number}
     * @memberof Telecommand
     */
    fileUploadStart?: number;
    /**
     * Specifies if this resource has an uploaded payload file
     * @type {boolean}
     * @memberof Telecommand
     */
    hasFile?: boolean;
    /**
     * ID of the Mission Database where this type of telecommand is defined
     * @type {string}
     * @memberof Telecommand
     */
    missionDatabase?: string;
    /**
     * Human friendly name of the Satellite Telecommand
     * @type {string}
     * @memberof Telecommand
     */
    name: string;
    /**
     * Base64 encoded value of the Telecommand\'s raw byte stream
     * @type {string}
     * @memberof Telecommand
     */
    rawData?: string;
    /**
     * A JSON String representation of the telecommand before binary encoding
     * @type {object}
     * @memberof Telecommand
     */
    data?: object;
    /**
     * Command priority
     * @type {number}
     * @memberof Telecommand
     */
    priority?: number;
    /**
     * ID of the Satellite for which the Telecommand is issued.
     * @type {string}
     * @memberof Telecommand
     */
    satellite: string;
    /**
     * ID of the Satellite Subsystem for which the Telecommand is issued.
     * @type {string}
     * @memberof Telecommand
     */
    satelliteSubsystem?: string;
    /**
     * ID of the Satellite Pass for which the Telecommand is scheduled. If null - Telecommand will be executed when possible
     * @type {string}
     * @memberof Telecommand
     */
    requestedSatellitePass?: string;
    /**
     * ID of the Satellite Pass during which the Telecommand was sent.
     * @type {string}
     * @memberof Telecommand
     */
    executedSatellitePass?: string;
    /**
     * Current status of the Telecommand
     * @type {string}
     * @memberof Telecommand
     */
    status?: TelecommandStatusEnum;
    /**
     * Specifies if the telecommand has a payload file 
     * @type {boolean}
     * @memberof Telecommand
     */
    hasPayloadFile?: boolean;
    /**
     * Specifies if a response is expected for this telecommand as a separate telemetry value
     * @type {boolean}
     * @memberof Telecommand
     */
    expectTelemetryResponse?: boolean;
    /**
     * Specifies the telemetry entry that contains the response of this telecommand. Will be empty if expectTelemetryResponse is false or if expectTelemetryResponse is true but the response has not arrived yet
     * @type {string}
     * @memberof Telecommand
     */
    telemetryResponse?: string;
    /**
     * Specifies the maximum number of execution attempts for the command before it is considered as failed.
     * @type {number}
     * @memberof Telecommand
     */
    maxTries?: number;
    /**
     * Specifies whether command execution should continue if thic command fails.
     * @type {boolean}
     * @memberof Telecommand
     */
    continueOnFail?: boolean;
    /**
     * 
     * @type {TelecommandGroundExecutionRules}
     * @memberof Telecommand
     */
    executionRules?: TelecommandGroundExecutionRules;
    /**
     * 
     * @type {TelecommandSatelliteExecutionRule}
     * @memberof Telecommand
     */
    satelliteExecutionRule?: TelecommandSatelliteExecutionRule;
    /**
     * Time at which the Telecommand was executed by the Ground Station
     * @type {number}
     * @memberof Telecommand
     */
    executedAtTimestamp?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum TelecommandStatusEnum {
    DRAFT = 'DRAFT',
    SCHEDULED = 'SCHEDULED',
    REQUESTED = 'REQUESTED',
    TESTED = 'TESTED',
    SENDING = 'SENDING',
    SENT = 'SENT',
    FAILED_TO_SEND = 'FAILED_TO_SEND',
    RECEIVED = 'RECEIVED',
    EXECUTING = 'EXECUTING',
    SUCCESSFUL = 'SUCCESSFUL',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
    TEMPLATE = 'TEMPLATE'
}

/**
 * Rules for executing this command
 * @export
 * @interface TelecommandGroundExecutionRules
 */
export interface TelecommandGroundExecutionRules {
    /**
     * The temlpate used to generate this command.
     * @type {string}
     * @memberof TelecommandGroundExecutionRules
     */
    telecommandTemplate?: string;
    /**
     * Execution type
     * @type {Array<ExecutionRule>}
     * @memberof TelecommandGroundExecutionRules
     */
    rules?: Array<ExecutionRule>;
}
/**
 * Rules for satellite execution of this command
 * @export
 * @interface TelecommandSatelliteExecutionRule
 */
export interface TelecommandSatelliteExecutionRule {
    /**
     * Start Time of the Telecommand execution
     * @type {number}
     * @memberof TelecommandSatelliteExecutionRule
     */
    start: number;
    /**
     * End Time of the Telecommand execution
     * @type {number}
     * @memberof TelecommandSatelliteExecutionRule
     */
    end: number;
    /**
     * 
     * @type {ADCSConfig}
     * @memberof TelecommandSatelliteExecutionRule
     */
    adcsConfig?: ADCSConfig;
}
/**
 * Telecommand for ConOps Two
 * @export
 * @interface TelecommandSimulationRequest
 */
export interface TelecommandSimulationRequest {
    /**
     * timestamp of the telecommand
     * @type {number}
     * @memberof TelecommandSimulationRequest
     */
    timestamp: number;
    /**
     * Uid of the telecommand
     * @type {number}
     * @memberof TelecommandSimulationRequest
     */
    uid: number;
    /**
     * flags of the telecommand
     * @type {number}
     * @memberof TelecommandSimulationRequest
     */
    flags: number;
    /**
     * 
     * @type {CommandConfig}
     * @memberof TelecommandSimulationRequest
     */
    command: CommandConfig;
}
/**
 * 
 * @export
 * @interface TelecommandsResultPage
 */
export interface TelecommandsResultPage {
    /**
     * Last evaluated item. Used for pagination.
     * @type {string}
     * @memberof TelecommandsResultPage
     */
    lastEvaluatedItem?: string;
    /**
     * The result data set, contained in the current page
     * @type {Array<Telecommand>}
     * @memberof TelecommandsResultPage
     */
    items?: Array<Telecommand>;
}
/**
 * 
 * @export
 * @interface TelemetryEntry
 */
export interface TelemetryEntry {
    /**
     * Object ID
     * @type {string}
     * @memberof TelemetryEntry
     */
    id?: string;
    /**
     * Start time of multipart upload in progress
     * @type {number}
     * @memberof TelemetryEntry
     */
    fileUploadStart?: number;
    /**
     * Specifies if this resource has an uploaded payload file
     * @type {boolean}
     * @memberof TelemetryEntry
     */
    hasFile?: boolean;
    /**
     * Human friendly name of the Telemetry Entry
     * @type {string}
     * @memberof TelemetryEntry
     */
    name?: string;
    /**
     * ID of the Mission Database where this type of telemetry entry is defined
     * @type {string}
     * @memberof TelemetryEntry
     */
    missionDatabase?: string;
    /**
     * Base64 encoded value of the Telemetry Entry\'s raw byte stream if hasPayloadFile is false
     * @type {string}
     * @memberof TelemetryEntry
     */
    rawData?: string;
    /**
     * Decoded (from the raw data) telemetry data represented as JSON String if hasPayloadFile is false
     * @type {object}
     * @memberof TelemetryEntry
     */
    data?: object;
    /**
     * Time at which the Telemetry Entry was received on the Ground Station
     * @type {number}
     * @memberof TelemetryEntry
     */
    receivedAtTimestamp: number;
    /**
     * ID of Satellite Pass during which the Telemetry Entry was recorded
     * @type {string}
     * @memberof TelemetryEntry
     */
    satellitePass: string;
    /**
     * ID of the Satellite from which the Telemetry Entry was recorded
     * @type {string}
     * @memberof TelemetryEntry
     */
    satellite: string;
    /**
     * ID of the Satellite Subsystem from which the Telemetry Entry was recorded
     * @type {string}
     * @memberof TelemetryEntry
     */
    satelliteSubsystem?: string;
    /**
     * Specifies if the telemetry value is a separate file (available for download from fileUrl) or contains the TM value in the same object
     * @type {boolean}
     * @memberof TelemetryEntry
     */
    hasPayloadFile?: boolean;
    /**
     * Specifies the telecommand request to which the current telemetry entry is a response. Will be empty if the telemetry entry is not a response to a telecommand
     * @type {string}
     * @memberof TelemetryEntry
     */
    telecommandRequest?: string;
}
/**
 * 
 * @export
 * @interface TelemetryResultPage
 */
export interface TelemetryResultPage {
    /**
     * Last evaluated item. Used for pagination.
     * @type {string}
     * @memberof TelemetryResultPage
     */
    lastEvaluatedItem?: string;
    /**
     * The result data set, contained in the current page
     * @type {Array<TelemetryEntry>}
     * @memberof TelemetryResultPage
     */
    items?: Array<TelemetryEntry>;
}
/**
 * Describes the available hardware on the radio layer
 * @export
 * @interface TypedDescriptor
 */
export interface TypedDescriptor {
    /**
     * Type of the component. Should match the type of a relevant Pass Lifecycle Component
     * @type {string}
     * @memberof TypedDescriptor
     */
    type?: string;
    /**
     * Configuration Properties as supported by EnduroSat
     * @type {{ [key: string]: string; }}
     * @memberof TypedDescriptor
     */
    configuration?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface UnavailabilityWindow
 */
export interface UnavailabilityWindow {
    /**
     * Object ID
     * @type {string}
     * @memberof UnavailabilityWindow
     */
    id?: string;
    /**
     * ID of the Ground Station for which the Unavailability Window applies
     * @type {string}
     * @memberof UnavailabilityWindow
     */
    groundStation: string;
    /**
     * Start Time of the Unavailability Window
     * @type {number}
     * @memberof UnavailabilityWindow
     */
    start: number;
    /**
     * End Time of the Unavailability Window
     * @type {number}
     * @memberof UnavailabilityWindow
     */
    end: number;
}
/**
 * 
 * @export
 * @interface UserDataRecord
 */
export interface UserDataRecord {
    /**
     * Object ID
     * @type {string}
     * @memberof UserDataRecord
     */
    id?: string;
    /**
     * A JSON String representation of a value
     * @type {object}
     * @memberof UserDataRecord
     */
    value?: object;
}
/**
 * Describes streams between the ground station and the Cloud layer
 * @export
 * @interface UserStreamDescriptor
 */
export interface UserStreamDescriptor {
    /**
     * 
     * @type {Set<StreamDescriptor>}
     * @memberof UserStreamDescriptor
     */
    streamDescriptor?: Set<StreamDescriptor>;
}

/**
 * AccessControlPolicyControllerApi - axios parameter creator
 * @export
 */
export const AccessControlPolicyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('_delete', 'name', name)
            const localVarPath = `/access-control-policies/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/access-control-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByName: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getByName', 'name', name)
            const localVarPath = `/access-control-policies/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CombinedAccessControlPolicyRequestDTO} combinedAccessControlPolicyRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save: async (combinedAccessControlPolicyRequestDTO: CombinedAccessControlPolicyRequestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'combinedAccessControlPolicyRequestDTO' is not null or undefined
            assertParamExists('save', 'combinedAccessControlPolicyRequestDTO', combinedAccessControlPolicyRequestDTO)
            const localVarPath = `/access-control-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedAccessControlPolicyRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CombinedAccessControlPolicyRequestDTO} combinedAccessControlPolicyRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (combinedAccessControlPolicyRequestDTO: CombinedAccessControlPolicyRequestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'combinedAccessControlPolicyRequestDTO' is not null or undefined
            assertParamExists('update', 'combinedAccessControlPolicyRequestDTO', combinedAccessControlPolicyRequestDTO)
            const localVarPath = `/access-control-policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(combinedAccessControlPolicyRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessControlPolicyControllerApi - functional programming interface
 * @export
 */
export const AccessControlPolicyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessControlPolicyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CombinedAccessControlPolicy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByName(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CombinedAccessControlPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CombinedAccessControlPolicyRequestDTO} combinedAccessControlPolicyRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async save(combinedAccessControlPolicyRequestDTO: CombinedAccessControlPolicyRequestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CombinedAccessControlPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.save(combinedAccessControlPolicyRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CombinedAccessControlPolicyRequestDTO} combinedAccessControlPolicyRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(combinedAccessControlPolicyRequestDTO: CombinedAccessControlPolicyRequestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CombinedAccessControlPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(combinedAccessControlPolicyRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessControlPolicyControllerApi - factory interface
 * @export
 */
export const AccessControlPolicyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessControlPolicyControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(name: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<Array<CombinedAccessControlPolicy>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByName(name: string, options?: any): AxiosPromise<CombinedAccessControlPolicy> {
            return localVarFp.getByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CombinedAccessControlPolicyRequestDTO} combinedAccessControlPolicyRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        save(combinedAccessControlPolicyRequestDTO: CombinedAccessControlPolicyRequestDTO, options?: any): AxiosPromise<CombinedAccessControlPolicy> {
            return localVarFp.save(combinedAccessControlPolicyRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CombinedAccessControlPolicyRequestDTO} combinedAccessControlPolicyRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(combinedAccessControlPolicyRequestDTO: CombinedAccessControlPolicyRequestDTO, options?: any): AxiosPromise<CombinedAccessControlPolicy> {
            return localVarFp.update(combinedAccessControlPolicyRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessControlPolicyControllerApi - object-oriented interface
 * @export
 * @class AccessControlPolicyControllerApi
 * @extends {BaseAPI}
 */
export class AccessControlPolicyControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlPolicyControllerApi
     */
    public _delete(name: string, options?: any) {
        return AccessControlPolicyControllerApiFp(this.configuration)._delete(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlPolicyControllerApi
     */
    public getAll(options?: any) {
        return AccessControlPolicyControllerApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlPolicyControllerApi
     */
    public getByName(name: string, options?: any) {
        return AccessControlPolicyControllerApiFp(this.configuration).getByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CombinedAccessControlPolicyRequestDTO} combinedAccessControlPolicyRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlPolicyControllerApi
     */
    public save(combinedAccessControlPolicyRequestDTO: CombinedAccessControlPolicyRequestDTO, options?: any) {
        return AccessControlPolicyControllerApiFp(this.configuration).save(combinedAccessControlPolicyRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CombinedAccessControlPolicyRequestDTO} combinedAccessControlPolicyRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessControlPolicyControllerApi
     */
    public update(combinedAccessControlPolicyRequestDTO: CombinedAccessControlPolicyRequestDTO, options?: any) {
        return AccessControlPolicyControllerApiFp(this.configuration).update(combinedAccessControlPolicyRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method authenticates a user or refreshes a token.
         * @summary Authenticates a user or refresh a token
         * @param {string} clientId App Client ID
         * @param {string} grantType The oauth2 grant type
         * @param {string} [username] Username - required for grant_type&#x3D;password
         * @param {string} [password] Password - required for grant_type&#x3D;password
         * @param {string} [refreshToken] Refresh Token - required for grant_type&#x3D;refresh_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate: async (clientId: string, grantType: string, username?: string, password?: string, refreshToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('authenticate', 'clientId', clientId)
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('authenticate', 'grantType', grantType)
            const localVarPath = `/oauth2/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (refreshToken !== undefined) { 
                localVarFormParams.set('refresh_token', refreshToken as any);
            }
    
            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method revokes a refreshe token.
         * @summary Revoke a refresh a token
         * @param {string} clientId App Client ID
         * @param {string} token The token to revoke
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: async (clientId: string, token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('revokeToken', 'clientId', clientId)
            // verify required parameter 'token' is not null or undefined
            assertParamExists('revokeToken', 'token', token)
            const localVarPath = `/oauth2/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (token !== undefined) { 
                localVarFormParams.set('token', token as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * This method authenticates a user or refreshes a token.
         * @summary Authenticates a user or refresh a token
         * @param {string} clientId App Client ID
         * @param {string} grantType The oauth2 grant type
         * @param {string} [username] Username - required for grant_type&#x3D;password
         * @param {string} [password] Password - required for grant_type&#x3D;password
         * @param {string} [refreshToken] Refresh Token - required for grant_type&#x3D;refresh_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticate(clientId: string, grantType: string, username?: string, password?: string, refreshToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(clientId, grantType, username, password, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method revokes a refreshe token.
         * @summary Revoke a refresh a token
         * @param {string} clientId App Client ID
         * @param {string} token The token to revoke
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeToken(clientId: string, token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revokeToken(clientId, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * This method authenticates a user or refreshes a token.
         * @summary Authenticates a user or refresh a token
         * @param {string} clientId App Client ID
         * @param {string} grantType The oauth2 grant type
         * @param {string} [username] Username - required for grant_type&#x3D;password
         * @param {string} [password] Password - required for grant_type&#x3D;password
         * @param {string} [refreshToken] Refresh Token - required for grant_type&#x3D;refresh_token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticate(clientId: string, grantType: string, username?: string, password?: string, refreshToken?: string, options?: any): AxiosPromise<AuthenticationResponse> {
            return localVarFp.authenticate(clientId, grantType, username, password, refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * This method revokes a refreshe token.
         * @summary Revoke a refresh a token
         * @param {string} clientId App Client ID
         * @param {string} token The token to revoke
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken(clientId: string, token: string, options?: any): AxiosPromise<void> {
            return localVarFp.revokeToken(clientId, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * This method authenticates a user or refreshes a token.
     * @summary Authenticates a user or refresh a token
     * @param {string} clientId App Client ID
     * @param {string} grantType The oauth2 grant type
     * @param {string} [username] Username - required for grant_type&#x3D;password
     * @param {string} [password] Password - required for grant_type&#x3D;password
     * @param {string} [refreshToken] Refresh Token - required for grant_type&#x3D;refresh_token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticate(clientId: string, grantType: string, username?: string, password?: string, refreshToken?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticate(clientId, grantType, username, password, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method revokes a refreshe token.
     * @summary Revoke a refresh a token
     * @param {string} clientId App Client ID
     * @param {string} token The token to revoke
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public revokeToken(clientId: string, token: string, options?: any) {
        return AuthenticationApiFp(this.configuration).revokeToken(clientId, token, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConjunctionsApi - axios parameter creator
 * @export
 */
export const ConjunctionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method returns a filtered list of conjunctions
         * @summary Get Conjunctions
         * @param {string} [conjunctionId] ID of the Conjunction
         * @param {string} [satelliteId] ID of the satellite for which to get the conjunction
         * @param {string} [startDate] Start date for which to get the conjunction
         * @param {string} [endDate] End date for which to get the conjunction
         * @param {string} [collisionProbability] The probability of the conjunction
         * @param {string} [page] Number of the page
         * @param {string} [perPage] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredConjunctions: async (conjunctionId?: string, satelliteId?: string, startDate?: string, endDate?: string, collisionProbability?: string, page?: string, perPage?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ca/conjunctions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (conjunctionId !== undefined) {
                localVarQueryParameter['conjunction-id'] = conjunctionId;
            }

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start-date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end-date'] = endDate;
            }

            if (collisionProbability !== undefined) {
                localVarQueryParameter['collision-probability'] = collisionProbability;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per-page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConjunctionsApi - functional programming interface
 * @export
 */
export const ConjunctionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConjunctionsApiAxiosParamCreator(configuration)
    return {
        /**
         * This method returns a filtered list of conjunctions
         * @summary Get Conjunctions
         * @param {string} [conjunctionId] ID of the Conjunction
         * @param {string} [satelliteId] ID of the satellite for which to get the conjunction
         * @param {string} [startDate] Start date for which to get the conjunction
         * @param {string} [endDate] End date for which to get the conjunction
         * @param {string} [collisionProbability] The probability of the conjunction
         * @param {string} [page] Number of the page
         * @param {string} [perPage] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredConjunctions(conjunctionId?: string, satelliteId?: string, startDate?: string, endDate?: string, collisionProbability?: string, page?: string, perPage?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConjunctionEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredConjunctions(conjunctionId, satelliteId, startDate, endDate, collisionProbability, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConjunctionsApi - factory interface
 * @export
 */
export const ConjunctionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConjunctionsApiFp(configuration)
    return {
        /**
         * This method returns a filtered list of conjunctions
         * @summary Get Conjunctions
         * @param {string} [conjunctionId] ID of the Conjunction
         * @param {string} [satelliteId] ID of the satellite for which to get the conjunction
         * @param {string} [startDate] Start date for which to get the conjunction
         * @param {string} [endDate] End date for which to get the conjunction
         * @param {string} [collisionProbability] The probability of the conjunction
         * @param {string} [page] Number of the page
         * @param {string} [perPage] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredConjunctions(conjunctionId?: string, satelliteId?: string, startDate?: string, endDate?: string, collisionProbability?: string, page?: string, perPage?: string, options?: any): AxiosPromise<ConjunctionEvent> {
            return localVarFp.getFilteredConjunctions(conjunctionId, satelliteId, startDate, endDate, collisionProbability, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConjunctionsApi - object-oriented interface
 * @export
 * @class ConjunctionsApi
 * @extends {BaseAPI}
 */
export class ConjunctionsApi extends BaseAPI {
    /**
     * This method returns a filtered list of conjunctions
     * @summary Get Conjunctions
     * @param {string} [conjunctionId] ID of the Conjunction
     * @param {string} [satelliteId] ID of the satellite for which to get the conjunction
     * @param {string} [startDate] Start date for which to get the conjunction
     * @param {string} [endDate] End date for which to get the conjunction
     * @param {string} [collisionProbability] The probability of the conjunction
     * @param {string} [page] Number of the page
     * @param {string} [perPage] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConjunctionsApi
     */
    public getFilteredConjunctions(conjunctionId?: string, satelliteId?: string, startDate?: string, endDate?: string, collisionProbability?: string, page?: string, perPage?: string, options?: any) {
        return ConjunctionsApiFp(this.configuration).getFilteredConjunctions(conjunctionId, satelliteId, startDate, endDate, collisionProbability, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DigitalTwinApi - axios parameter creator
 * @export
 */
export const DigitalTwinApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method returns all simulations
         * @summary Returns all simulations
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllSimulations: async (lastEvaluatedItem?: string, pageSizeLimit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/digital-twin/simulations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns the simulation details including the output files of the simulation
         * @summary Returns the simulation details including the output files of the simulation
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimulationOutputFiles: async (simulationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('retrieveSimulationOutputFiles', 'simulationId', simulationId)
            const localVarPath = `/digital-twin/simulations/{simulationId}`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns the simulation result files details including download url
         * @summary Returns the simulation result files details
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimulationResultFiles: async (simulationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationId' is not null or undefined
            assertParamExists('retrieveSimulationResultFiles', 'simulationId', simulationId)
            const localVarPath = `/digital-twin/simulations/{simulationId}/result-files`
                .replace(`{${"simulationId"}}`, encodeURIComponent(String(simulationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method runs simulation for a scheduled telecommand
         * @summary Run simulation for a scheduled telecommand
         * @param {SimulationRequest} simulationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSimulation: async (simulationRequest: SimulationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'simulationRequest' is not null or undefined
            assertParamExists('startSimulation', 'simulationRequest', simulationRequest)
            const localVarPath = `/digital-twin/simulations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(simulationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DigitalTwinApi - functional programming interface
 * @export
 */
export const DigitalTwinApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DigitalTwinApiAxiosParamCreator(configuration)
    return {
        /**
         * This method returns all simulations
         * @summary Returns all simulations
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAllSimulations(lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulaitonResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveAllSimulations(lastEvaluatedItem, pageSizeLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns the simulation details including the output files of the simulation
         * @summary Returns the simulation details including the output files of the simulation
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSimulationOutputFiles(simulationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Simulation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSimulationOutputFiles(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns the simulation result files details including download url
         * @summary Returns the simulation result files details
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveSimulationResultFiles(simulationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimulationResultFilesDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrieveSimulationResultFiles(simulationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method runs simulation for a scheduled telecommand
         * @summary Run simulation for a scheduled telecommand
         * @param {SimulationRequest} simulationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSimulation(simulationRequest: SimulationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSimulation(simulationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DigitalTwinApi - factory interface
 * @export
 */
export const DigitalTwinApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DigitalTwinApiFp(configuration)
    return {
        /**
         * This method returns all simulations
         * @summary Returns all simulations
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllSimulations(lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any): AxiosPromise<SimulaitonResultPage> {
            return localVarFp.retrieveAllSimulations(lastEvaluatedItem, pageSizeLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns the simulation details including the output files of the simulation
         * @summary Returns the simulation details including the output files of the simulation
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimulationOutputFiles(simulationId: string, options?: any): AxiosPromise<Simulation> {
            return localVarFp.retrieveSimulationOutputFiles(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns the simulation result files details including download url
         * @summary Returns the simulation result files details
         * @param {string} simulationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveSimulationResultFiles(simulationId: string, options?: any): AxiosPromise<SimulationResultFilesDetails> {
            return localVarFp.retrieveSimulationResultFiles(simulationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method runs simulation for a scheduled telecommand
         * @summary Run simulation for a scheduled telecommand
         * @param {SimulationRequest} simulationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSimulation(simulationRequest: SimulationRequest, options?: any): AxiosPromise<string> {
            return localVarFp.startSimulation(simulationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DigitalTwinApi - object-oriented interface
 * @export
 * @class DigitalTwinApi
 * @extends {BaseAPI}
 */
export class DigitalTwinApi extends BaseAPI {
    /**
     * This method returns all simulations
     * @summary Returns all simulations
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalTwinApi
     */
    public retrieveAllSimulations(lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any) {
        return DigitalTwinApiFp(this.configuration).retrieveAllSimulations(lastEvaluatedItem, pageSizeLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns the simulation details including the output files of the simulation
     * @summary Returns the simulation details including the output files of the simulation
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalTwinApi
     */
    public retrieveSimulationOutputFiles(simulationId: string, options?: any) {
        return DigitalTwinApiFp(this.configuration).retrieveSimulationOutputFiles(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns the simulation result files details including download url
     * @summary Returns the simulation result files details
     * @param {string} simulationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalTwinApi
     */
    public retrieveSimulationResultFiles(simulationId: string, options?: any) {
        return DigitalTwinApiFp(this.configuration).retrieveSimulationResultFiles(simulationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method runs simulation for a scheduled telecommand
     * @summary Run simulation for a scheduled telecommand
     * @param {SimulationRequest} simulationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DigitalTwinApi
     */
    public startSimulation(simulationRequest: SimulationRequest, options?: any) {
        return DigitalTwinApiFp(this.configuration).startSimulation(simulationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventSubscriptionsApi - axios parameter creator
 * @export
 */
export const EventSubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method creates an event subscription
         * @summary Create an event subscription
         * @param {CreateEventSubscriptionRequest} createEventSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventSubscription: async (createEventSubscriptionRequest: CreateEventSubscriptionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEventSubscriptionRequest' is not null or undefined
            assertParamExists('createEventSubscription', 'createEventSubscriptionRequest', createEventSubscriptionRequest)
            const localVarPath = `/event-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns an event subscription by event type
         * @summary Get event subscription by event type
         * @param {string} [eventType] Event Type
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredEventSubscriptions: async (eventType?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/event-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventSubscriptionsApi - functional programming interface
 * @export
 */
export const EventSubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventSubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * This method creates an event subscription
         * @summary Create an event subscription
         * @param {CreateEventSubscriptionRequest} createEventSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEventSubscription(createEventSubscriptionRequest: CreateEventSubscriptionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEventSubscription(createEventSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns an event subscription by event type
         * @summary Get event subscription by event type
         * @param {string} [eventType] Event Type
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredEventSubscriptions(eventType?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventSubscriptionsResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredEventSubscriptions(eventType, lastEvaluatedItem, pageSizeLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventSubscriptionsApi - factory interface
 * @export
 */
export const EventSubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventSubscriptionsApiFp(configuration)
    return {
        /**
         * This method creates an event subscription
         * @summary Create an event subscription
         * @param {CreateEventSubscriptionRequest} createEventSubscriptionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEventSubscription(createEventSubscriptionRequest: CreateEventSubscriptionRequest, options?: any): AxiosPromise<string> {
            return localVarFp.createEventSubscription(createEventSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns an event subscription by event type
         * @summary Get event subscription by event type
         * @param {string} [eventType] Event Type
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredEventSubscriptions(eventType?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any): AxiosPromise<EventSubscriptionsResultPage> {
            return localVarFp.getFilteredEventSubscriptions(eventType, lastEvaluatedItem, pageSizeLimit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventSubscriptionsApi - object-oriented interface
 * @export
 * @class EventSubscriptionsApi
 * @extends {BaseAPI}
 */
export class EventSubscriptionsApi extends BaseAPI {
    /**
     * This method creates an event subscription
     * @summary Create an event subscription
     * @param {CreateEventSubscriptionRequest} createEventSubscriptionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventSubscriptionsApi
     */
    public createEventSubscription(createEventSubscriptionRequest: CreateEventSubscriptionRequest, options?: any) {
        return EventSubscriptionsApiFp(this.configuration).createEventSubscription(createEventSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns an event subscription by event type
     * @summary Get event subscription by event type
     * @param {string} [eventType] Event Type
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventSubscriptionsApi
     */
    public getFilteredEventSubscriptions(eventType?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any) {
        return EventSubscriptionsApiFp(this.configuration).getFilteredEventSubscriptions(eventType, lastEvaluatedItem, pageSizeLimit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method publishes an event to the aws event bus
         * @summary Publish an event
         * @param {EventPayload} eventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishEvent: async (eventPayload: EventPayload, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventPayload' is not null or undefined
            assertParamExists('publishEvent', 'eventPayload', eventPayload)
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventPayload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * This method publishes an event to the aws event bus
         * @summary Publish an event
         * @param {EventPayload} eventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishEvent(eventPayload: EventPayload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishEvent(eventPayload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * This method publishes an event to the aws event bus
         * @summary Publish an event
         * @param {EventPayload} eventPayload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishEvent(eventPayload: EventPayload, options?: any): AxiosPromise<void> {
            return localVarFp.publishEvent(eventPayload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * This method publishes an event to the aws event bus
     * @summary Publish an event
     * @param {EventPayload} eventPayload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public publishEvent(eventPayload: EventPayload, options?: any) {
        return EventsApiFp(this.configuration).publishEvent(eventPayload, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventsTypesApi - axios parameter creator
 * @export
 */
export const EventsTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a list of all available event types.
         * @summary Get all Event Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/event-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsTypesApi - functional programming interface
 * @export
 */
export const EventsTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get a list of all available event types.
         * @summary Get all Event Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEventTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEventTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsTypesApi - factory interface
 * @export
 */
export const EventsTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsTypesApiFp(configuration)
    return {
        /**
         * Get a list of all available event types.
         * @summary Get all Event Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventTypes(options?: any): AxiosPromise<Array<EventType>> {
            return localVarFp.getAllEventTypes(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsTypesApi - object-oriented interface
 * @export
 * @class EventsTypesApi
 * @extends {BaseAPI}
 */
export class EventsTypesApi extends BaseAPI {
    /**
     * Get a list of all available event types.
     * @summary Get all Event Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsTypesApi
     */
    public getAllEventTypes(options?: any) {
        return EventsTypesApiFp(this.configuration).getAllEventTypes(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroundStationsApi - axios parameter creator
 * @export
 */
export const GroundStationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method adds an Unavailability Window to a specified Ground Station
         * @summary Add an Unavailability Window to a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which add Unavailability Windows
         * @param {UnavailabilityWindow} unavailabilityWindow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnavailabilityWindowsByGroundStationById: async (groundStationId: string, unavailabilityWindow: UnavailabilityWindow, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groundStationId' is not null or undefined
            assertParamExists('addUnavailabilityWindowsByGroundStationById', 'groundStationId', groundStationId)
            // verify required parameter 'unavailabilityWindow' is not null or undefined
            assertParamExists('addUnavailabilityWindowsByGroundStationById', 'unavailabilityWindow', unavailabilityWindow)
            const localVarPath = `/ground-stations/{groundStationId}/unavailability-windows`
                .replace(`{${"groundStationId"}}`, encodeURIComponent(String(groundStationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unavailabilityWindow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method creates a new Ground Stations
         * @summary Create a new Ground Station
         * @param {GroundStation} groundStation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroundStation: async (groundStation: GroundStation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groundStation' is not null or undefined
            assertParamExists('createGroundStation', 'groundStation', groundStation)
            const localVarPath = `/ground-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groundStation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method deletes a Ground Station with the specified ID
         * @summary Delete a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroundStation: async (groundStationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groundStationId' is not null or undefined
            assertParamExists('deleteGroundStation', 'groundStationId', groundStationId)
            const localVarPath = `/ground-stations/{groundStationId}`
                .replace(`{${"groundStationId"}}`, encodeURIComponent(String(groundStationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method deletes an Unavailability Window from a specified Ground Station
         * @summary Delete an Unavailability Window from a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which delete Unavailability Windows
         * @param {string} unavailabilityWindowId ID of the Unavailability Window to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnavailabilityWindow: async (groundStationId: string, unavailabilityWindowId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groundStationId' is not null or undefined
            assertParamExists('deleteUnavailabilityWindow', 'groundStationId', groundStationId)
            // verify required parameter 'unavailabilityWindowId' is not null or undefined
            assertParamExists('deleteUnavailabilityWindow', 'unavailabilityWindowId', unavailabilityWindowId)
            const localVarPath = `/ground-stations/{groundStationId}/unavailability-windows/{unavailabilityWindowId}`
                .replace(`{${"groundStationId"}}`, encodeURIComponent(String(groundStationId)))
                .replace(`{${"unavailabilityWindowId"}}`, encodeURIComponent(String(unavailabilityWindowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a list of all Ground Stations
         * @summary Get all Ground Stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroundStations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ground-stations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Ground Station by ID
         * @summary Get a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroundStationById: async (groundStationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groundStationId' is not null or undefined
            assertParamExists('getGroundStationById', 'groundStationId', groundStationId)
            const localVarPath = `/ground-stations/{groundStationId}`
                .replace(`{${"groundStationId"}}`, encodeURIComponent(String(groundStationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a list of all Unavailability Windows for a specified Ground Station
         * @summary Get all Unavailability Windowss for a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which to return Unavailability Windows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnavailabilityWindowsByGroundStationById: async (groundStationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groundStationId' is not null or undefined
            assertParamExists('getUnavailabilityWindowsByGroundStationById', 'groundStationId', groundStationId)
            const localVarPath = `/ground-stations/{groundStationId}/unavailability-windows`
                .replace(`{${"groundStationId"}}`, encodeURIComponent(String(groundStationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method updates a Ground Station by ID
         * @summary Update a Ground Station
         * @param {string} groundStationId ID of the Ground Station to update
         * @param {GroundStation} groundStation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroundStation: async (groundStationId: string, groundStation: GroundStation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groundStationId' is not null or undefined
            assertParamExists('updateGroundStation', 'groundStationId', groundStationId)
            // verify required parameter 'groundStation' is not null or undefined
            assertParamExists('updateGroundStation', 'groundStation', groundStation)
            const localVarPath = `/ground-stations/{groundStationId}`
                .replace(`{${"groundStationId"}}`, encodeURIComponent(String(groundStationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groundStation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroundStationsApi - functional programming interface
 * @export
 */
export const GroundStationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroundStationsApiAxiosParamCreator(configuration)
    return {
        /**
         * This method adds an Unavailability Window to a specified Ground Station
         * @summary Add an Unavailability Window to a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which add Unavailability Windows
         * @param {UnavailabilityWindow} unavailabilityWindow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUnavailabilityWindowsByGroundStationById(groundStationId: string, unavailabilityWindow: UnavailabilityWindow, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUnavailabilityWindowsByGroundStationById(groundStationId, unavailabilityWindow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method creates a new Ground Stations
         * @summary Create a new Ground Station
         * @param {GroundStation} groundStation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroundStation(groundStation: GroundStation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroundStation(groundStation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method deletes a Ground Station with the specified ID
         * @summary Delete a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroundStation(groundStationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroundStation(groundStationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method deletes an Unavailability Window from a specified Ground Station
         * @summary Delete an Unavailability Window from a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which delete Unavailability Windows
         * @param {string} unavailabilityWindowId ID of the Unavailability Window to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUnavailabilityWindow(groundStationId: string, unavailabilityWindowId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUnavailabilityWindow(groundStationId, unavailabilityWindowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a list of all Ground Stations
         * @summary Get all Ground Stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllGroundStations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroundStation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllGroundStations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Ground Station by ID
         * @summary Get a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroundStationById(groundStationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroundStation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroundStationById(groundStationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a list of all Unavailability Windows for a specified Ground Station
         * @summary Get all Unavailability Windowss for a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which to return Unavailability Windows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnavailabilityWindowsByGroundStationById(groundStationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnavailabilityWindow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnavailabilityWindowsByGroundStationById(groundStationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method updates a Ground Station by ID
         * @summary Update a Ground Station
         * @param {string} groundStationId ID of the Ground Station to update
         * @param {GroundStation} groundStation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroundStation(groundStationId: string, groundStation: GroundStation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroundStation(groundStationId, groundStation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroundStationsApi - factory interface
 * @export
 */
export const GroundStationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroundStationsApiFp(configuration)
    return {
        /**
         * This method adds an Unavailability Window to a specified Ground Station
         * @summary Add an Unavailability Window to a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which add Unavailability Windows
         * @param {UnavailabilityWindow} unavailabilityWindow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUnavailabilityWindowsByGroundStationById(groundStationId: string, unavailabilityWindow: UnavailabilityWindow, options?: any): AxiosPromise<void> {
            return localVarFp.addUnavailabilityWindowsByGroundStationById(groundStationId, unavailabilityWindow, options).then((request) => request(axios, basePath));
        },
        /**
         * This method creates a new Ground Stations
         * @summary Create a new Ground Station
         * @param {GroundStation} groundStation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroundStation(groundStation: GroundStation, options?: any): AxiosPromise<void> {
            return localVarFp.createGroundStation(groundStation, options).then((request) => request(axios, basePath));
        },
        /**
         * This method deletes a Ground Station with the specified ID
         * @summary Delete a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroundStation(groundStationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroundStation(groundStationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method deletes an Unavailability Window from a specified Ground Station
         * @summary Delete an Unavailability Window from a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which delete Unavailability Windows
         * @param {string} unavailabilityWindowId ID of the Unavailability Window to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnavailabilityWindow(groundStationId: string, unavailabilityWindowId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUnavailabilityWindow(groundStationId, unavailabilityWindowId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a list of all Ground Stations
         * @summary Get all Ground Stations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGroundStations(options?: any): AxiosPromise<Array<GroundStation>> {
            return localVarFp.getAllGroundStations(options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Ground Station by ID
         * @summary Get a Ground Station by ID
         * @param {string} groundStationId ID of the Ground Station to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroundStationById(groundStationId: string, options?: any): AxiosPromise<GroundStation> {
            return localVarFp.getGroundStationById(groundStationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a list of all Unavailability Windows for a specified Ground Station
         * @summary Get all Unavailability Windowss for a Ground Station
         * @param {string} groundStationId ID of the Ground Station for which to return Unavailability Windows
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnavailabilityWindowsByGroundStationById(groundStationId: string, options?: any): AxiosPromise<Array<UnavailabilityWindow>> {
            return localVarFp.getUnavailabilityWindowsByGroundStationById(groundStationId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method updates a Ground Station by ID
         * @summary Update a Ground Station
         * @param {string} groundStationId ID of the Ground Station to update
         * @param {GroundStation} groundStation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroundStation(groundStationId: string, groundStation: GroundStation, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroundStation(groundStationId, groundStation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroundStationsApi - object-oriented interface
 * @export
 * @class GroundStationsApi
 * @extends {BaseAPI}
 */
export class GroundStationsApi extends BaseAPI {
    /**
     * This method adds an Unavailability Window to a specified Ground Station
     * @summary Add an Unavailability Window to a Ground Station
     * @param {string} groundStationId ID of the Ground Station for which add Unavailability Windows
     * @param {UnavailabilityWindow} unavailabilityWindow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    public addUnavailabilityWindowsByGroundStationById(groundStationId: string, unavailabilityWindow: UnavailabilityWindow, options?: any) {
        return GroundStationsApiFp(this.configuration).addUnavailabilityWindowsByGroundStationById(groundStationId, unavailabilityWindow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method creates a new Ground Stations
     * @summary Create a new Ground Station
     * @param {GroundStation} groundStation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    public createGroundStation(groundStation: GroundStation, options?: any) {
        return GroundStationsApiFp(this.configuration).createGroundStation(groundStation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method deletes a Ground Station with the specified ID
     * @summary Delete a Ground Station by ID
     * @param {string} groundStationId ID of the Ground Station to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    public deleteGroundStation(groundStationId: string, options?: any) {
        return GroundStationsApiFp(this.configuration).deleteGroundStation(groundStationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method deletes an Unavailability Window from a specified Ground Station
     * @summary Delete an Unavailability Window from a Ground Station
     * @param {string} groundStationId ID of the Ground Station for which delete Unavailability Windows
     * @param {string} unavailabilityWindowId ID of the Unavailability Window to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    public deleteUnavailabilityWindow(groundStationId: string, unavailabilityWindowId: string, options?: any) {
        return GroundStationsApiFp(this.configuration).deleteUnavailabilityWindow(groundStationId, unavailabilityWindowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a list of all Ground Stations
     * @summary Get all Ground Stations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    public getAllGroundStations(options?: any) {
        return GroundStationsApiFp(this.configuration).getAllGroundStations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Ground Station by ID
     * @summary Get a Ground Station by ID
     * @param {string} groundStationId ID of the Ground Station to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    public getGroundStationById(groundStationId: string, options?: any) {
        return GroundStationsApiFp(this.configuration).getGroundStationById(groundStationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a list of all Unavailability Windows for a specified Ground Station
     * @summary Get all Unavailability Windowss for a Ground Station
     * @param {string} groundStationId ID of the Ground Station for which to return Unavailability Windows
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    public getUnavailabilityWindowsByGroundStationById(groundStationId: string, options?: any) {
        return GroundStationsApiFp(this.configuration).getUnavailabilityWindowsByGroundStationById(groundStationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method updates a Ground Station by ID
     * @summary Update a Ground Station
     * @param {string} groundStationId ID of the Ground Station to update
     * @param {GroundStation} groundStation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroundStationsApi
     */
    public updateGroundStation(groundStationId: string, groundStation: GroundStation, options?: any) {
        return GroundStationsApiFp(this.configuration).updateGroundStation(groundStationId, groundStation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MissionDatabasesApi - axios parameter creator
 * @export
 */
export const MissionDatabasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method creates new mission database.
         * @summary Create a Mission Database
         * @param {MissionDatabase} missionDatabase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissionDatabase: async (missionDatabase: MissionDatabase, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabase' is not null or undefined
            assertParamExists('createMissionDatabase', 'missionDatabase', missionDatabase)
            const localVarPath = `/mission-databases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionDatabase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method generates new parsers and mission databases based on deploy model.
         * @summary Generate fidl parsers and mission databases from deployment model
         * @param {any} fidlPackage The archive containing the fidl files and deployment model
         * @param {string} [deploymentModel] Name of .fdepl deployment model file (excluding the .fdepl extension)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissionDatabasePackage: async (fidlPackage: any, deploymentModel?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fidlPackage' is not null or undefined
            assertParamExists('createMissionDatabasePackage', 'fidlPackage', fidlPackage)
            const localVarPath = `/mission-databases/fidl-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


            if (fidlPackage !== undefined) { 
                localVarFormParams.append('fidlPackage', fidlPackage as any);
            }
    
            if (deploymentModel !== undefined) { 
                localVarFormParams.append('deploymentModel', deploymentModel as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method will upload .
         * @summary Upload mission database operation script
         * @param {string} missionDatabaseId ID of the Mission Database
         * @param {string} procedureDescription Short explanation what is the script expected to do
         * @param {string} procedureName Short explanation what is the script expected to do
         * @param {any} procedureScripts The archive containing the operation scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcedureScripts: async (missionDatabaseId: string, procedureDescription: string, procedureName: string, procedureScripts: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabaseId' is not null or undefined
            assertParamExists('createProcedureScripts', 'missionDatabaseId', missionDatabaseId)
            // verify required parameter 'procedureDescription' is not null or undefined
            assertParamExists('createProcedureScripts', 'procedureDescription', procedureDescription)
            // verify required parameter 'procedureName' is not null or undefined
            assertParamExists('createProcedureScripts', 'procedureName', procedureName)
            // verify required parameter 'procedureScripts' is not null or undefined
            assertParamExists('createProcedureScripts', 'procedureScripts', procedureScripts)
            const localVarPath = `/mission-databases/{missionDatabaseId}/procedure-scripts`
                .replace(`{${"missionDatabaseId"}}`, encodeURIComponent(String(missionDatabaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (procedureDescription !== undefined) {
                localVarQueryParameter['procedureDescription'] = procedureDescription;
            }

            if (procedureName !== undefined) {
                localVarQueryParameter['procedureName'] = procedureName;
            }


            if (procedureScripts !== undefined) { 
                localVarFormParams.append('procedureScripts', procedureScripts as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method deletes a MissionDatabase with the specified ID
         * @summary Delete a MissionDatabase by ID
         * @param {string} missionDatabaseId ID of the MissionDatabase to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissionDatabase: async (missionDatabaseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabaseId' is not null or undefined
            assertParamExists('deleteMissionDatabase', 'missionDatabaseId', missionDatabaseId)
            const localVarPath = `/mission-databases/{missionDatabaseId}`
                .replace(`{${"missionDatabaseId"}}`, encodeURIComponent(String(missionDatabaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method deletes a Mission Database Package with the specified ID along with all associated mission databases and files
         * @summary Delete all files and mission databases for package
         * @param {string} packageId ID of the Mission Database Package to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissionDatabasePackage: async (packageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageId' is not null or undefined
            assertParamExists('deleteMissionDatabasePackage', 'packageId', packageId)
            const localVarPath = `/mission-databases/fidl-packages/{packageId}`
                .replace(`{${"packageId"}}`, encodeURIComponent(String(packageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method deletes a Mission Database Package with the specified ID along with all associated mission databases and files
         * @summary Delete a script for this mission database
         * @param {string} missionDatabaseId ID of the Mission Database
         * @param {string} procedureName Name of the procedure script that will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcedureScripts: async (missionDatabaseId: string, procedureName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabaseId' is not null or undefined
            assertParamExists('deleteProcedureScripts', 'missionDatabaseId', missionDatabaseId)
            // verify required parameter 'procedureName' is not null or undefined
            assertParamExists('deleteProcedureScripts', 'procedureName', procedureName)
            const localVarPath = `/mission-databases/{missionDatabaseId}/procedure-scripts/{procedureName}`
                .replace(`{${"missionDatabaseId"}}`, encodeURIComponent(String(missionDatabaseId)))
                .replace(`{${"procedureName"}}`, encodeURIComponent(String(procedureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a filtered list of Mission Databases for a satellite. Mission Databases could be filtered by Satellite, Satellite Subsytem, name and type
         * @summary Get Mission Databases
         * @param {string} [satelliteId] Specifies the Satellite for which the Mission Database is applicable
         * @param {string} [satelliteSubsystemId] Specifies the Satellite Subsystem for which the Mission Database is applicable
         * @param {string} [name] Specifies the name of the Mission Database
         * @param {string} [type] Specifies the type of the Mission Database
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredMissionDatabase: async (satelliteId?: string, satelliteSubsystemId?: string, name?: string, type?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission-databases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (satelliteSubsystemId !== undefined) {
                localVarQueryParameter['satelliteSubsystemId'] = satelliteSubsystemId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Mission Database by ID.
         * @summary Get Mission Database By ID
         * @param {string} missionDatabaseId ID of the Mission Database to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseById: async (missionDatabaseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabaseId' is not null or undefined
            assertParamExists('getMissionDatabaseById', 'missionDatabaseId', missionDatabaseId)
            const localVarPath = `/mission-databases/{missionDatabaseId}`
                .replace(`{${"missionDatabaseId"}}`, encodeURIComponent(String(missionDatabaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Mission Database Definition based on the ID of a Mission Database for the current user
         * @summary Get Mission Database Definition by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseDefinition: async (missionDatabaseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabaseId' is not null or undefined
            assertParamExists('getMissionDatabaseDefinition', 'missionDatabaseId', missionDatabaseId)
            const localVarPath = `/mission-databases/{missionDatabaseId}/definition`
                .replace(`{${"missionDatabaseId"}}`, encodeURIComponent(String(missionDatabaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a mission database package by Id.
         * @summary Get mission database package
         * @param {string} packageId ID of the package generated from the fidl source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabasePackagById: async (packageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageId' is not null or undefined
            assertParamExists('getMissionDatabasePackagById', 'packageId', packageId)
            const localVarPath = `/mission-databases/fidl-packages/{packageId}`
                .replace(`{${"packageId"}}`, encodeURIComponent(String(packageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a url to download the archive with fidl files used for the generation of the package.
         * @summary Get fidl package source
         * @param {string} packageId ID of the package generated from the fidl source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabasePackageSourceById: async (packageId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'packageId' is not null or undefined
            assertParamExists('getMissionDatabasePackageSourceById', 'packageId', packageId)
            const localVarPath = `/mission-databases/fidl-packages/{packageId}/source`
                .replace(`{${"packageId"}}`, encodeURIComponent(String(packageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a URL from which to download Mission Database Parser based on the ID of a Mission Database for the current user
         * @summary Get URL from which to download Mission Database Fidl Parser by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseParser: async (missionDatabaseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabaseId' is not null or undefined
            assertParamExists('getMissionDatabaseParser', 'missionDatabaseId', missionDatabaseId)
            const localVarPath = `/mission-databases/{missionDatabaseId}/parser`
                .replace(`{${"missionDatabaseId"}}`, encodeURIComponent(String(missionDatabaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Mission Database Procedure based on the ID of a Mission Database and Procedure Name for the current user
         * @summary Get Mission Database Procedure by Mission Database ID and Procedure Name
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Procedure
         * @param {string} procedureName Name of the Procedure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseProcedureScript: async (missionDatabaseId: string, procedureName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabaseId' is not null or undefined
            assertParamExists('getMissionDatabaseProcedureScript', 'missionDatabaseId', missionDatabaseId)
            // verify required parameter 'procedureName' is not null or undefined
            assertParamExists('getMissionDatabaseProcedureScript', 'procedureName', procedureName)
            const localVarPath = `/mission-databases/{missionDatabaseId}/procedure/{procedureName}`
                .replace(`{${"missionDatabaseId"}}`, encodeURIComponent(String(missionDatabaseId)))
                .replace(`{${"procedureName"}}`, encodeURIComponent(String(procedureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method updates a MissionDatabase by ID
         * @summary Update a MissionDatabase
         * @param {string} missionDatabaseId ID of the MissionDatabase to update
         * @param {MissionDatabase} missionDatabase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissionDatabase: async (missionDatabaseId: string, missionDatabase: MissionDatabase, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'missionDatabaseId' is not null or undefined
            assertParamExists('updateMissionDatabase', 'missionDatabaseId', missionDatabaseId)
            // verify required parameter 'missionDatabase' is not null or undefined
            assertParamExists('updateMissionDatabase', 'missionDatabase', missionDatabase)
            const localVarPath = `/mission-databases/{missionDatabaseId}`
                .replace(`{${"missionDatabaseId"}}`, encodeURIComponent(String(missionDatabaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(missionDatabase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionDatabasesApi - functional programming interface
 * @export
 */
export const MissionDatabasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionDatabasesApiAxiosParamCreator(configuration)
    return {
        /**
         * This method creates new mission database.
         * @summary Create a Mission Database
         * @param {MissionDatabase} missionDatabase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMissionDatabase(missionDatabase: MissionDatabase, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMissionDatabase(missionDatabase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method generates new parsers and mission databases based on deploy model.
         * @summary Generate fidl parsers and mission databases from deployment model
         * @param {any} fidlPackage The archive containing the fidl files and deployment model
         * @param {string} [deploymentModel] Name of .fdepl deployment model file (excluding the .fdepl extension)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMissionDatabasePackage(fidlPackage: any, deploymentModel?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMissionDatabasePackage(fidlPackage, deploymentModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method will upload .
         * @summary Upload mission database operation script
         * @param {string} missionDatabaseId ID of the Mission Database
         * @param {string} procedureDescription Short explanation what is the script expected to do
         * @param {string} procedureName Short explanation what is the script expected to do
         * @param {any} procedureScripts The archive containing the operation scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProcedureScripts(missionDatabaseId: string, procedureDescription: string, procedureName: string, procedureScripts: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProcedureScripts(missionDatabaseId, procedureDescription, procedureName, procedureScripts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method deletes a MissionDatabase with the specified ID
         * @summary Delete a MissionDatabase by ID
         * @param {string} missionDatabaseId ID of the MissionDatabase to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMissionDatabase(missionDatabaseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMissionDatabase(missionDatabaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method deletes a Mission Database Package with the specified ID along with all associated mission databases and files
         * @summary Delete all files and mission databases for package
         * @param {string} packageId ID of the Mission Database Package to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMissionDatabasePackage(packageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMissionDatabasePackage(packageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method deletes a Mission Database Package with the specified ID along with all associated mission databases and files
         * @summary Delete a script for this mission database
         * @param {string} missionDatabaseId ID of the Mission Database
         * @param {string} procedureName Name of the procedure script that will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProcedureScripts(missionDatabaseId: string, procedureName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProcedureScripts(missionDatabaseId, procedureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a filtered list of Mission Databases for a satellite. Mission Databases could be filtered by Satellite, Satellite Subsytem, name and type
         * @summary Get Mission Databases
         * @param {string} [satelliteId] Specifies the Satellite for which the Mission Database is applicable
         * @param {string} [satelliteSubsystemId] Specifies the Satellite Subsystem for which the Mission Database is applicable
         * @param {string} [name] Specifies the name of the Mission Database
         * @param {string} [type] Specifies the type of the Mission Database
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredMissionDatabase(satelliteId?: string, satelliteSubsystemId?: string, name?: string, type?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionDatabasesResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredMissionDatabase(satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Mission Database by ID.
         * @summary Get Mission Database By ID
         * @param {string} missionDatabaseId ID of the Mission Database to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMissionDatabaseById(missionDatabaseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionDatabase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMissionDatabaseById(missionDatabaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Mission Database Definition based on the ID of a Mission Database for the current user
         * @summary Get Mission Database Definition by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMissionDatabaseDefinition(missionDatabaseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMissionDatabaseDefinition(missionDatabaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a mission database package by Id.
         * @summary Get mission database package
         * @param {string} packageId ID of the package generated from the fidl source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMissionDatabasePackagById(packageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionDatabasePackage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMissionDatabasePackagById(packageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a url to download the archive with fidl files used for the generation of the package.
         * @summary Get fidl package source
         * @param {string} packageId ID of the package generated from the fidl source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMissionDatabasePackageSourceById(packageId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMissionDatabasePackageSourceById(packageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a URL from which to download Mission Database Parser based on the ID of a Mission Database for the current user
         * @summary Get URL from which to download Mission Database Fidl Parser by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMissionDatabaseParser(missionDatabaseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMissionDatabaseParser(missionDatabaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Mission Database Procedure based on the ID of a Mission Database and Procedure Name for the current user
         * @summary Get Mission Database Procedure by Mission Database ID and Procedure Name
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Procedure
         * @param {string} procedureName Name of the Procedure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMissionDatabaseProcedureScript(missionDatabaseId: string, procedureName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMissionDatabaseProcedureScript(missionDatabaseId, procedureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method updates a MissionDatabase by ID
         * @summary Update a MissionDatabase
         * @param {string} missionDatabaseId ID of the MissionDatabase to update
         * @param {MissionDatabase} missionDatabase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMissionDatabase(missionDatabaseId: string, missionDatabase: MissionDatabase, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMissionDatabase(missionDatabaseId, missionDatabase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MissionDatabasesApi - factory interface
 * @export
 */
export const MissionDatabasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionDatabasesApiFp(configuration)
    return {
        /**
         * This method creates new mission database.
         * @summary Create a Mission Database
         * @param {MissionDatabase} missionDatabase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissionDatabase(missionDatabase: MissionDatabase, options?: any): AxiosPromise<string> {
            return localVarFp.createMissionDatabase(missionDatabase, options).then((request) => request(axios, basePath));
        },
        /**
         * This method generates new parsers and mission databases based on deploy model.
         * @summary Generate fidl parsers and mission databases from deployment model
         * @param {any} fidlPackage The archive containing the fidl files and deployment model
         * @param {string} [deploymentModel] Name of .fdepl deployment model file (excluding the .fdepl extension)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissionDatabasePackage(fidlPackage: any, deploymentModel?: string, options?: any): AxiosPromise<string> {
            return localVarFp.createMissionDatabasePackage(fidlPackage, deploymentModel, options).then((request) => request(axios, basePath));
        },
        /**
         * This method will upload .
         * @summary Upload mission database operation script
         * @param {string} missionDatabaseId ID of the Mission Database
         * @param {string} procedureDescription Short explanation what is the script expected to do
         * @param {string} procedureName Short explanation what is the script expected to do
         * @param {any} procedureScripts The archive containing the operation scripts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProcedureScripts(missionDatabaseId: string, procedureDescription: string, procedureName: string, procedureScripts: any, options?: any): AxiosPromise<string> {
            return localVarFp.createProcedureScripts(missionDatabaseId, procedureDescription, procedureName, procedureScripts, options).then((request) => request(axios, basePath));
        },
        /**
         * This method deletes a MissionDatabase with the specified ID
         * @summary Delete a MissionDatabase by ID
         * @param {string} missionDatabaseId ID of the MissionDatabase to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissionDatabase(missionDatabaseId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMissionDatabase(missionDatabaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method deletes a Mission Database Package with the specified ID along with all associated mission databases and files
         * @summary Delete all files and mission databases for package
         * @param {string} packageId ID of the Mission Database Package to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMissionDatabasePackage(packageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMissionDatabasePackage(packageId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method deletes a Mission Database Package with the specified ID along with all associated mission databases and files
         * @summary Delete a script for this mission database
         * @param {string} missionDatabaseId ID of the Mission Database
         * @param {string} procedureName Name of the procedure script that will be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProcedureScripts(missionDatabaseId: string, procedureName: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProcedureScripts(missionDatabaseId, procedureName, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a filtered list of Mission Databases for a satellite. Mission Databases could be filtered by Satellite, Satellite Subsytem, name and type
         * @summary Get Mission Databases
         * @param {string} [satelliteId] Specifies the Satellite for which the Mission Database is applicable
         * @param {string} [satelliteSubsystemId] Specifies the Satellite Subsystem for which the Mission Database is applicable
         * @param {string} [name] Specifies the name of the Mission Database
         * @param {string} [type] Specifies the type of the Mission Database
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredMissionDatabase(satelliteId?: string, satelliteSubsystemId?: string, name?: string, type?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any): AxiosPromise<MissionDatabasesResultPage> {
            return localVarFp.getFilteredMissionDatabase(satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Mission Database by ID.
         * @summary Get Mission Database By ID
         * @param {string} missionDatabaseId ID of the Mission Database to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseById(missionDatabaseId: string, options?: any): AxiosPromise<MissionDatabase> {
            return localVarFp.getMissionDatabaseById(missionDatabaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Mission Database Definition based on the ID of a Mission Database for the current user
         * @summary Get Mission Database Definition by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Definition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseDefinition(missionDatabaseId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getMissionDatabaseDefinition(missionDatabaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a mission database package by Id.
         * @summary Get mission database package
         * @param {string} packageId ID of the package generated from the fidl source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabasePackagById(packageId: string, options?: any): AxiosPromise<MissionDatabasePackage> {
            return localVarFp.getMissionDatabasePackagById(packageId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a url to download the archive with fidl files used for the generation of the package.
         * @summary Get fidl package source
         * @param {string} packageId ID of the package generated from the fidl source
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabasePackageSourceById(packageId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getMissionDatabasePackageSourceById(packageId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a URL from which to download Mission Database Parser based on the ID of a Mission Database for the current user
         * @summary Get URL from which to download Mission Database Fidl Parser by Mission Database ID
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the URL
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseParser(missionDatabaseId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getMissionDatabaseParser(missionDatabaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Mission Database Procedure based on the ID of a Mission Database and Procedure Name for the current user
         * @summary Get Mission Database Procedure by Mission Database ID and Procedure Name
         * @param {string} missionDatabaseId ID of the Mission Database for which to get the Procedure
         * @param {string} procedureName Name of the Procedure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMissionDatabaseProcedureScript(missionDatabaseId: string, procedureName: string, options?: any): AxiosPromise<string> {
            return localVarFp.getMissionDatabaseProcedureScript(missionDatabaseId, procedureName, options).then((request) => request(axios, basePath));
        },
        /**
         * This method updates a MissionDatabase by ID
         * @summary Update a MissionDatabase
         * @param {string} missionDatabaseId ID of the MissionDatabase to update
         * @param {MissionDatabase} missionDatabase 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMissionDatabase(missionDatabaseId: string, missionDatabase: MissionDatabase, options?: any): AxiosPromise<void> {
            return localVarFp.updateMissionDatabase(missionDatabaseId, missionDatabase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MissionDatabasesApi - object-oriented interface
 * @export
 * @class MissionDatabasesApi
 * @extends {BaseAPI}
 */
export class MissionDatabasesApi extends BaseAPI {
    /**
     * This method creates new mission database.
     * @summary Create a Mission Database
     * @param {MissionDatabase} missionDatabase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public createMissionDatabase(missionDatabase: MissionDatabase, options?: any) {
        return MissionDatabasesApiFp(this.configuration).createMissionDatabase(missionDatabase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method generates new parsers and mission databases based on deploy model.
     * @summary Generate fidl parsers and mission databases from deployment model
     * @param {any} fidlPackage The archive containing the fidl files and deployment model
     * @param {string} [deploymentModel] Name of .fdepl deployment model file (excluding the .fdepl extension)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public createMissionDatabasePackage(fidlPackage: any, deploymentModel?: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).createMissionDatabasePackage(fidlPackage, deploymentModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method will upload .
     * @summary Upload mission database operation script
     * @param {string} missionDatabaseId ID of the Mission Database
     * @param {string} procedureDescription Short explanation what is the script expected to do
     * @param {string} procedureName Short explanation what is the script expected to do
     * @param {any} procedureScripts The archive containing the operation scripts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public createProcedureScripts(missionDatabaseId: string, procedureDescription: string, procedureName: string, procedureScripts: any, options?: any) {
        return MissionDatabasesApiFp(this.configuration).createProcedureScripts(missionDatabaseId, procedureDescription, procedureName, procedureScripts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method deletes a MissionDatabase with the specified ID
     * @summary Delete a MissionDatabase by ID
     * @param {string} missionDatabaseId ID of the MissionDatabase to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public deleteMissionDatabase(missionDatabaseId: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).deleteMissionDatabase(missionDatabaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method deletes a Mission Database Package with the specified ID along with all associated mission databases and files
     * @summary Delete all files and mission databases for package
     * @param {string} packageId ID of the Mission Database Package to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public deleteMissionDatabasePackage(packageId: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).deleteMissionDatabasePackage(packageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method deletes a Mission Database Package with the specified ID along with all associated mission databases and files
     * @summary Delete a script for this mission database
     * @param {string} missionDatabaseId ID of the Mission Database
     * @param {string} procedureName Name of the procedure script that will be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public deleteProcedureScripts(missionDatabaseId: string, procedureName: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).deleteProcedureScripts(missionDatabaseId, procedureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a filtered list of Mission Databases for a satellite. Mission Databases could be filtered by Satellite, Satellite Subsytem, name and type
     * @summary Get Mission Databases
     * @param {string} [satelliteId] Specifies the Satellite for which the Mission Database is applicable
     * @param {string} [satelliteSubsystemId] Specifies the Satellite Subsystem for which the Mission Database is applicable
     * @param {string} [name] Specifies the name of the Mission Database
     * @param {string} [type] Specifies the type of the Mission Database
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public getFilteredMissionDatabase(satelliteId?: string, satelliteSubsystemId?: string, name?: string, type?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any) {
        return MissionDatabasesApiFp(this.configuration).getFilteredMissionDatabase(satelliteId, satelliteSubsystemId, name, type, lastEvaluatedItem, pageSizeLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Mission Database by ID.
     * @summary Get Mission Database By ID
     * @param {string} missionDatabaseId ID of the Mission Database to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public getMissionDatabaseById(missionDatabaseId: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).getMissionDatabaseById(missionDatabaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Mission Database Definition based on the ID of a Mission Database for the current user
     * @summary Get Mission Database Definition by Mission Database ID
     * @param {string} missionDatabaseId ID of the Mission Database for which to get the Definition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public getMissionDatabaseDefinition(missionDatabaseId: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).getMissionDatabaseDefinition(missionDatabaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a mission database package by Id.
     * @summary Get mission database package
     * @param {string} packageId ID of the package generated from the fidl source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public getMissionDatabasePackagById(packageId: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).getMissionDatabasePackagById(packageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a url to download the archive with fidl files used for the generation of the package.
     * @summary Get fidl package source
     * @param {string} packageId ID of the package generated from the fidl source
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public getMissionDatabasePackageSourceById(packageId: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).getMissionDatabasePackageSourceById(packageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a URL from which to download Mission Database Parser based on the ID of a Mission Database for the current user
     * @summary Get URL from which to download Mission Database Fidl Parser by Mission Database ID
     * @param {string} missionDatabaseId ID of the Mission Database for which to get the URL
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public getMissionDatabaseParser(missionDatabaseId: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).getMissionDatabaseParser(missionDatabaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Mission Database Procedure based on the ID of a Mission Database and Procedure Name for the current user
     * @summary Get Mission Database Procedure by Mission Database ID and Procedure Name
     * @param {string} missionDatabaseId ID of the Mission Database for which to get the Procedure
     * @param {string} procedureName Name of the Procedure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public getMissionDatabaseProcedureScript(missionDatabaseId: string, procedureName: string, options?: any) {
        return MissionDatabasesApiFp(this.configuration).getMissionDatabaseProcedureScript(missionDatabaseId, procedureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method updates a MissionDatabase by ID
     * @summary Update a MissionDatabase
     * @param {string} missionDatabaseId ID of the MissionDatabase to update
     * @param {MissionDatabase} missionDatabase 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionDatabasesApi
     */
    public updateMissionDatabase(missionDatabaseId: string, missionDatabase: MissionDatabase, options?: any) {
        return MissionDatabasesApiFp(this.configuration).updateMissionDatabase(missionDatabaseId, missionDatabase, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PayloadFilesApi - axios parameter creator
 * @export
 */
export const PayloadFilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method finishes a multipart upload
         * @summary Finish multipart upload
         * @param {string} multipartUploadId 
         * @param {Array<string>} eTags 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishMultipartUpload: async (multipartUploadId: string, eTags: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'multipartUploadId' is not null or undefined
            assertParamExists('finishMultipartUpload', 'multipartUploadId', multipartUploadId)
            // verify required parameter 'eTags' is not null or undefined
            assertParamExists('finishMultipartUpload', 'eTags', eTags)
            const localVarPath = `/payload-files/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (multipartUploadId !== undefined) {
                localVarQueryParameter['MultipartUploadId'] = multipartUploadId;
            }

            if (eTags) {
                localVarQueryParameter['eTags'] = eTags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a URL from which to download a file
         * @summary (DEPRECATED) Get a URL from which to download a file. Use GET /{resourceType}/{resourceId}/artifacts/{artifactType} instead.
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadURL: async (resourceType: string, resourceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getDownloadURL', 'resourceType', resourceType)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getDownloadURL', 'resourceId', resourceId)
            const localVarPath = `/payload-files/{resourceType}/{resourceId}`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a URL from which to download a file
         * @summary Get a URL from which to download a file
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadURL1: async (resourceType: string, resourceId: string, artifactType: ArtifactType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('getDownloadURL1', 'resourceType', resourceType)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('getDownloadURL1', 'resourceId', resourceId)
            // verify required parameter 'artifactType' is not null or undefined
            assertParamExists('getDownloadURL1', 'artifactType', artifactType)
            const localVarPath = `/payload-files/{resourceType}/{resourceId}/artifacts/{artifactType}`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"artifactType"}}`, encodeURIComponent(String(artifactType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method begins a multipart upload
         * @summary Initiate Multipart Upload
         * @param {string} resourceType Type of the resource for which to init a file upload. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to init a file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateMultipartUpload: async (resourceType: string, resourceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('initiateMultipartUpload', 'resourceType', resourceType)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('initiateMultipartUpload', 'resourceId', resourceId)
            const localVarPath = `/payload-files/multipart/{resourceType}/{resourceId}`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary Attach payload file to resouce
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to upload
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArtifactFile: async (resourceType: string, resourceId: string, artifactType: ArtifactType, payload: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('uploadArtifactFile', 'resourceType', resourceType)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('uploadArtifactFile', 'resourceId', resourceId)
            // verify required parameter 'artifactType' is not null or undefined
            assertParamExists('uploadArtifactFile', 'artifactType', artifactType)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('uploadArtifactFile', 'payload', payload)
            const localVarPath = `/payload-files/{resourceType}/{resourceId}/artifacts/{artifactType}`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)))
                .replace(`{${"artifactType"}}`, encodeURIComponent(String(artifactType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


            if (payload !== undefined) { 
                localVarFormParams.append('payload', payload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary (DEPRECATED) Attach payload file to resouce. Use PUT /{resourceType}/{resourceId}/artifacts/{artifactType} instead.
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (resourceType: string, resourceId: string, payload: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceType' is not null or undefined
            assertParamExists('uploadFile', 'resourceType', resourceType)
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('uploadFile', 'resourceId', resourceId)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('uploadFile', 'payload', payload)
            const localVarPath = `/payload-files/{resourceType}/{resourceId}`
                .replace(`{${"resourceType"}}`, encodeURIComponent(String(resourceType)))
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


            if (payload !== undefined) { 
                localVarFormParams.append('payload', payload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method uploads a part of a multipart file upload
         * @summary Upload Part
         * @param {string} multipartUploadId 
         * @param {number} partNumber 
         * @param {any} payload 
         * @param {boolean} [lastPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPart: async (multipartUploadId: string, partNumber: number, payload: any, lastPart?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'multipartUploadId' is not null or undefined
            assertParamExists('uploadPart', 'multipartUploadId', multipartUploadId)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('uploadPart', 'partNumber', partNumber)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('uploadPart', 'payload', payload)
            const localVarPath = `/payload-files/upload-part`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (multipartUploadId !== undefined) {
                localVarQueryParameter['MultipartUploadId'] = multipartUploadId;
            }

            if (partNumber !== undefined) {
                localVarQueryParameter['partNumber'] = partNumber;
            }

            if (lastPart !== undefined) {
                localVarQueryParameter['lastPart'] = lastPart;
            }


            if (payload !== undefined) { 
                localVarFormParams.append('payload', payload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayloadFilesApi - functional programming interface
 * @export
 */
export const PayloadFilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayloadFilesApiAxiosParamCreator(configuration)
    return {
        /**
         * This method finishes a multipart upload
         * @summary Finish multipart upload
         * @param {string} multipartUploadId 
         * @param {Array<string>} eTags 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async finishMultipartUpload(multipartUploadId: string, eTags: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.finishMultipartUpload(multipartUploadId, eTags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a URL from which to download a file
         * @summary (DEPRECATED) Get a URL from which to download a file. Use GET /{resourceType}/{resourceId}/artifacts/{artifactType} instead.
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadURL(resourceType: string, resourceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadURL(resourceType, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a URL from which to download a file
         * @summary Get a URL from which to download a file
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadURL1(resourceType: string, resourceId: string, artifactType: ArtifactType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadURL1(resourceType, resourceId, artifactType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method begins a multipart upload
         * @summary Initiate Multipart Upload
         * @param {string} resourceType Type of the resource for which to init a file upload. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to init a file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async initiateMultipartUpload(resourceType: string, resourceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.initiateMultipartUpload(resourceType, resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary Attach payload file to resouce
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to upload
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadArtifactFile(resourceType: string, resourceId: string, artifactType: ArtifactType, payload: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadArtifactFile(resourceType, resourceId, artifactType, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary (DEPRECATED) Attach payload file to resouce. Use PUT /{resourceType}/{resourceId}/artifacts/{artifactType} instead.
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(resourceType: string, resourceId: string, payload: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(resourceType, resourceId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method uploads a part of a multipart file upload
         * @summary Upload Part
         * @param {string} multipartUploadId 
         * @param {number} partNumber 
         * @param {any} payload 
         * @param {boolean} [lastPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPart(multipartUploadId: string, partNumber: number, payload: any, lastPart?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPart(multipartUploadId, partNumber, payload, lastPart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayloadFilesApi - factory interface
 * @export
 */
export const PayloadFilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayloadFilesApiFp(configuration)
    return {
        /**
         * This method finishes a multipart upload
         * @summary Finish multipart upload
         * @param {string} multipartUploadId 
         * @param {Array<string>} eTags 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finishMultipartUpload(multipartUploadId: string, eTags: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.finishMultipartUpload(multipartUploadId, eTags, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a URL from which to download a file
         * @summary (DEPRECATED) Get a URL from which to download a file. Use GET /{resourceType}/{resourceId}/artifacts/{artifactType} instead.
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadURL(resourceType: string, resourceId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getDownloadURL(resourceType, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a URL from which to download a file
         * @summary Get a URL from which to download a file
         * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to get a file
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadURL1(resourceType: string, resourceId: string, artifactType: ArtifactType, options?: any): AxiosPromise<string> {
            return localVarFp.getDownloadURL1(resourceType, resourceId, artifactType, options).then((request) => request(axios, basePath));
        },
        /**
         * This method begins a multipart upload
         * @summary Initiate Multipart Upload
         * @param {string} resourceType Type of the resource for which to init a file upload. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to init a file upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        initiateMultipartUpload(resourceType: string, resourceId: string, options?: any): AxiosPromise<string> {
            return localVarFp.initiateMultipartUpload(resourceType, resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary Attach payload file to resouce
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to upload
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArtifactFile(resourceType: string, resourceId: string, artifactType: ArtifactType, payload: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadArtifactFile(resourceType, resourceId, artifactType, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * This method attaches a payload file to a resouce
         * @summary (DEPRECATED) Attach payload file to resouce. Use PUT /{resourceType}/{resourceId}/artifacts/{artifactType} instead.
         * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
         * @param {string} resourceId Id of the resource for which to upload a file
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(resourceType: string, resourceId: string, payload: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadFile(resourceType, resourceId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * This method uploads a part of a multipart file upload
         * @summary Upload Part
         * @param {string} multipartUploadId 
         * @param {number} partNumber 
         * @param {any} payload 
         * @param {boolean} [lastPart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPart(multipartUploadId: string, partNumber: number, payload: any, lastPart?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.uploadPart(multipartUploadId, partNumber, payload, lastPart, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayloadFilesApi - object-oriented interface
 * @export
 * @class PayloadFilesApi
 * @extends {BaseAPI}
 */
export class PayloadFilesApi extends BaseAPI {
    /**
     * This method finishes a multipart upload
     * @summary Finish multipart upload
     * @param {string} multipartUploadId 
     * @param {Array<string>} eTags 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    public finishMultipartUpload(multipartUploadId: string, eTags: Array<string>, options?: any) {
        return PayloadFilesApiFp(this.configuration).finishMultipartUpload(multipartUploadId, eTags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a URL from which to download a file
     * @summary (DEPRECATED) Get a URL from which to download a file. Use GET /{resourceType}/{resourceId}/artifacts/{artifactType} instead.
     * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
     * @param {string} resourceId Id of the resource for which to get a file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    public getDownloadURL(resourceType: string, resourceId: string, options?: any) {
        return PayloadFilesApiFp(this.configuration).getDownloadURL(resourceType, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a URL from which to download a file
     * @summary Get a URL from which to download a file
     * @param {string} resourceType Type of the resource for which to get a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
     * @param {string} resourceId Id of the resource for which to get a file
     * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    public getDownloadURL1(resourceType: string, resourceId: string, artifactType: ArtifactType, options?: any) {
        return PayloadFilesApiFp(this.configuration).getDownloadURL1(resourceType, resourceId, artifactType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method begins a multipart upload
     * @summary Initiate Multipart Upload
     * @param {string} resourceType Type of the resource for which to init a file upload. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
     * @param {string} resourceId Id of the resource for which to init a file upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    public initiateMultipartUpload(resourceType: string, resourceId: string, options?: any) {
        return PayloadFilesApiFp(this.configuration).initiateMultipartUpload(resourceType, resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method attaches a payload file to a resouce
     * @summary Attach payload file to resouce
     * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
     * @param {string} resourceId Id of the resource for which to upload a file
     * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to upload
     * @param {any} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    public uploadArtifactFile(resourceType: string, resourceId: string, artifactType: ArtifactType, payload: any, options?: any) {
        return PayloadFilesApiFp(this.configuration).uploadArtifactFile(resourceType, resourceId, artifactType, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method attaches a payload file to a resouce
     * @summary (DEPRECATED) Attach payload file to resouce. Use PUT /{resourceType}/{resourceId}/artifacts/{artifactType} instead.
     * @param {string} resourceType Type of the resource for which to upload a file. Possible values are \&#39;telemetry\&#39;, \&#39;telecommand\&#39; and \&#39;satellite-pass\&#39;.
     * @param {string} resourceId Id of the resource for which to upload a file
     * @param {any} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    public uploadFile(resourceType: string, resourceId: string, payload: any, options?: any) {
        return PayloadFilesApiFp(this.configuration).uploadFile(resourceType, resourceId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method uploads a part of a multipart file upload
     * @summary Upload Part
     * @param {string} multipartUploadId 
     * @param {number} partNumber 
     * @param {any} payload 
     * @param {boolean} [lastPart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadFilesApi
     */
    public uploadPart(multipartUploadId: string, partNumber: number, payload: any, lastPart?: boolean, options?: any) {
        return PayloadFilesApiFp(this.configuration).uploadPart(multipartUploadId, partNumber, payload, lastPart, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PayloadSlotsApi - axios parameter creator
 * @export
 */
export const PayloadSlotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method creates a new Payload Slot for the current user.
         * @summary Create a Payload Slot
         * @param {PayloadSlot} payloadSlot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayloadSlot: async (payloadSlot: PayloadSlot, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payloadSlot' is not null or undefined
            assertParamExists('createPayloadSlot', 'payloadSlot', payloadSlot)
            const localVarPath = `/payload-slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payloadSlot, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Payload Slot by ID
         * @summary Get a Payload Slot by ID
         * @param {string} payloadSlotId ID of the Payload Slot to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlotById: async (payloadSlotId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payloadSlotId' is not null or undefined
            assertParamExists('getPayloadSlotById', 'payloadSlotId', payloadSlotId)
            const localVarPath = `/payload-slots/{payloadSlotId}`
                .replace(`{${"payloadSlotId"}}`, encodeURIComponent(String(payloadSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a list of available Payload Slots for a payload satellite subsytem
         * @summary Get a list of available Payload Slots
         * @param {string} satelliteSubsystem ID of the satellite subsystem (payload) to get slots for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlots: async (satelliteSubsystem: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satelliteSubsystem' is not null or undefined
            assertParamExists('getPayloadSlots', 'satelliteSubsystem', satelliteSubsystem)
            const localVarPath = `/payload-slots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satelliteSubsystem !== undefined) {
                localVarQueryParameter['satelliteSubsystem'] = satelliteSubsystem;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayloadSlotsApi - functional programming interface
 * @export
 */
export const PayloadSlotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayloadSlotsApiAxiosParamCreator(configuration)
    return {
        /**
         * This method creates a new Payload Slot for the current user.
         * @summary Create a Payload Slot
         * @param {PayloadSlot} payloadSlot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayloadSlot(payloadSlot: PayloadSlot, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPayloadSlot(payloadSlot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Payload Slot by ID
         * @summary Get a Payload Slot by ID
         * @param {string} payloadSlotId ID of the Payload Slot to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayloadSlotById(payloadSlotId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayloadSlot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayloadSlotById(payloadSlotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a list of available Payload Slots for a payload satellite subsytem
         * @summary Get a list of available Payload Slots
         * @param {string} satelliteSubsystem ID of the satellite subsystem (payload) to get slots for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayloadSlots(satelliteSubsystem: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PayloadSlot>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayloadSlots(satelliteSubsystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayloadSlotsApi - factory interface
 * @export
 */
export const PayloadSlotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayloadSlotsApiFp(configuration)
    return {
        /**
         * This method creates a new Payload Slot for the current user.
         * @summary Create a Payload Slot
         * @param {PayloadSlot} payloadSlot 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayloadSlot(payloadSlot: PayloadSlot, options?: any): AxiosPromise<string> {
            return localVarFp.createPayloadSlot(payloadSlot, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Payload Slot by ID
         * @summary Get a Payload Slot by ID
         * @param {string} payloadSlotId ID of the Payload Slot to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlotById(payloadSlotId: string, options?: any): AxiosPromise<PayloadSlot> {
            return localVarFp.getPayloadSlotById(payloadSlotId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a list of available Payload Slots for a payload satellite subsytem
         * @summary Get a list of available Payload Slots
         * @param {string} satelliteSubsystem ID of the satellite subsystem (payload) to get slots for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayloadSlots(satelliteSubsystem: string, options?: any): AxiosPromise<Array<PayloadSlot>> {
            return localVarFp.getPayloadSlots(satelliteSubsystem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayloadSlotsApi - object-oriented interface
 * @export
 * @class PayloadSlotsApi
 * @extends {BaseAPI}
 */
export class PayloadSlotsApi extends BaseAPI {
    /**
     * This method creates a new Payload Slot for the current user.
     * @summary Create a Payload Slot
     * @param {PayloadSlot} payloadSlot 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadSlotsApi
     */
    public createPayloadSlot(payloadSlot: PayloadSlot, options?: any) {
        return PayloadSlotsApiFp(this.configuration).createPayloadSlot(payloadSlot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Payload Slot by ID
     * @summary Get a Payload Slot by ID
     * @param {string} payloadSlotId ID of the Payload Slot to get.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadSlotsApi
     */
    public getPayloadSlotById(payloadSlotId: string, options?: any) {
        return PayloadSlotsApiFp(this.configuration).getPayloadSlotById(payloadSlotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a list of available Payload Slots for a payload satellite subsytem
     * @summary Get a list of available Payload Slots
     * @param {string} satelliteSubsystem ID of the satellite subsystem (payload) to get slots for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayloadSlotsApi
     */
    public getPayloadSlots(satelliteSubsystem: string, options?: any) {
        return PayloadSlotsApiFp(this.configuration).getPayloadSlots(satelliteSubsystem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SatellitePassPredictionsApi - axios parameter creator
 * @export
 */
export const SatellitePassPredictionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method returns a list of pass predictions that could be filtered by satellite, ground station and time window. These predictions can then be used to schedule a pass. Each prediction is valid for 20 minutes. After that the prediction API need to be called again.
         * @summary Get a list of pass predictions
         * @param {string} satellite ID of the satellite for which to filter.
         * @param {string} [groundStation] ID of the ground station for which to filter. If not present results for all ground stations are returned.
         * @param {number} [hoursAhead] Specified the time window in hours from now for which to predict passes. Defaults to 10.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassPredictions: async (satellite: string, groundStation?: string, hoursAhead?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satellite' is not null or undefined
            assertParamExists('getPassPredictions', 'satellite', satellite)
            const localVarPath = `/pass-predictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satellite !== undefined) {
                localVarQueryParameter['satellite'] = satellite;
            }

            if (groundStation !== undefined) {
                localVarQueryParameter['ground-station'] = groundStation;
            }

            if (hoursAhead !== undefined) {
                localVarQueryParameter['hours-ahead'] = hoursAhead;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SatellitePassPredictionsApi - functional programming interface
 * @export
 */
export const SatellitePassPredictionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SatellitePassPredictionsApiAxiosParamCreator(configuration)
    return {
        /**
         * This method returns a list of pass predictions that could be filtered by satellite, ground station and time window. These predictions can then be used to schedule a pass. Each prediction is valid for 20 minutes. After that the prediction API need to be called again.
         * @summary Get a list of pass predictions
         * @param {string} satellite ID of the satellite for which to filter.
         * @param {string} [groundStation] ID of the ground station for which to filter. If not present results for all ground stations are returned.
         * @param {number} [hoursAhead] Specified the time window in hours from now for which to predict passes. Defaults to 10.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPassPredictions(satellite: string, groundStation?: string, hoursAhead?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PassPrediction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPassPredictions(satellite, groundStation, hoursAhead, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SatellitePassPredictionsApi - factory interface
 * @export
 */
export const SatellitePassPredictionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SatellitePassPredictionsApiFp(configuration)
    return {
        /**
         * This method returns a list of pass predictions that could be filtered by satellite, ground station and time window. These predictions can then be used to schedule a pass. Each prediction is valid for 20 minutes. After that the prediction API need to be called again.
         * @summary Get a list of pass predictions
         * @param {string} satellite ID of the satellite for which to filter.
         * @param {string} [groundStation] ID of the ground station for which to filter. If not present results for all ground stations are returned.
         * @param {number} [hoursAhead] Specified the time window in hours from now for which to predict passes. Defaults to 10.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPassPredictions(satellite: string, groundStation?: string, hoursAhead?: number, options?: any): AxiosPromise<Array<PassPrediction>> {
            return localVarFp.getPassPredictions(satellite, groundStation, hoursAhead, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SatellitePassPredictionsApi - object-oriented interface
 * @export
 * @class SatellitePassPredictionsApi
 * @extends {BaseAPI}
 */
export class SatellitePassPredictionsApi extends BaseAPI {
    /**
     * This method returns a list of pass predictions that could be filtered by satellite, ground station and time window. These predictions can then be used to schedule a pass. Each prediction is valid for 20 minutes. After that the prediction API need to be called again.
     * @summary Get a list of pass predictions
     * @param {string} satellite ID of the satellite for which to filter.
     * @param {string} [groundStation] ID of the ground station for which to filter. If not present results for all ground stations are returned.
     * @param {number} [hoursAhead] Specified the time window in hours from now for which to predict passes. Defaults to 10.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassPredictionsApi
     */
    public getPassPredictions(satellite: string, groundStation?: string, hoursAhead?: number, options?: any) {
        return SatellitePassPredictionsApiFp(this.configuration).getPassPredictions(satellite, groundStation, hoursAhead, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SatellitePassesApi - axios parameter creator
 * @export
 */
export const SatellitePassesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method cancels a satellite pass by ID for the current user
         * @summary Cancel a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSatellitePass: async (satellitePassId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satellitePassId' is not null or undefined
            assertParamExists('cancelSatellitePass', 'satellitePassId', satellitePassId)
            const localVarPath = `/satellite-passes/{satellitePassId}`
                .replace(`{${"satellitePassId"}}`, encodeURIComponent(String(satellitePassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method is used for booking passes for the current user. It requires a Pass Prediction obtained from the Pass Prediction API to be provided in the body of the request. Pass predictions are valid for 20 minutes.If the prediction has expired or is ivalid, a 400 Bad Request response will be returned.
         * @summary Book a Satellite Pass
         * @param {PassPrediction} passPrediction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellitePass: async (passPrediction: PassPrediction, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passPrediction' is not null or undefined
            assertParamExists('createSatellitePass', 'passPrediction', passPrediction)
            const localVarPath = `/satellite-passes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passPrediction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a filtered list of Satellite Passes. Satellite Passes could be filtered by Satellite, Ground Station and Status
         * @summary Get Filtered Satellite Passes
         * @param {string} [satelliteId] Specifies the satellite for which to get Satellite Passes
         * @param {Array<string>} [groundStation] Specifies the Ground Station(s) of the Satellite Passes to get.
         * @param {'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED'} [status] Specifies the Status (or part of the Status) of the Satellite Passes to get
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatellitePasses: async (satelliteId?: string, groundStation?: Array<string>, status?: 'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED', fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/satellite-passes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (groundStation) {
                localVarQueryParameter['groundStation'] = groundStation;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = toTime;
            }

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a pass by ID for the current user
         * @summary Get a Satellite Pass by ID
         * @param {string} satellitePassId ID of the Satellite Pass to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatellitePassById: async (satellitePassId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satellitePassId' is not null or undefined
            assertParamExists('getSatellitePassById', 'satellitePassId', satellitePassId)
            const localVarPath = `/satellite-passes/{satellitePassId}`
                .replace(`{${"satellitePassId"}}`, encodeURIComponent(String(satellitePassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method records artifacts of the satellite pass
         * @summary Record a Satellite Pass Artifact
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to upload
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordSatellitePassArtifact: async (satellitePassId: string, artifactType: ArtifactType, payload: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satellitePassId' is not null or undefined
            assertParamExists('recordSatellitePassArtifact', 'satellitePassId', satellitePassId)
            // verify required parameter 'artifactType' is not null or undefined
            assertParamExists('recordSatellitePassArtifact', 'artifactType', artifactType)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('recordSatellitePassArtifact', 'payload', payload)
            const localVarPath = `/satellite-passes/{satellitePassId}/artifacts/{artifactType}`
                .replace(`{${"satellitePassId"}}`, encodeURIComponent(String(satellitePassId)))
                .replace(`{${"artifactType"}}`, encodeURIComponent(String(artifactType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


            if (payload !== undefined) { 
                localVarFormParams.append('payload', payload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method records a log of the satellite pass
         * @summary Record a Satellite Pass Log
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordSatellitePassLog: async (satellitePassId: string, payload: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satellitePassId' is not null or undefined
            assertParamExists('recordSatellitePassLog', 'satellitePassId', satellitePassId)
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('recordSatellitePassLog', 'payload', payload)
            const localVarPath = `/satellite-passes/{satellitePassId}/log`
                .replace(`{${"satellitePassId"}}`, encodeURIComponent(String(satellitePassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


            if (payload !== undefined) { 
                localVarFormParams.append('payload', payload as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method updates a satellite pass by ID for the current user
         * @summary Update a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {SatellitePass} satellitePass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatellitePassById: async (satellitePassId: string, satellitePass: SatellitePass, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satellitePassId' is not null or undefined
            assertParamExists('updateSatellitePassById', 'satellitePassId', satellitePassId)
            // verify required parameter 'satellitePass' is not null or undefined
            assertParamExists('updateSatellitePassById', 'satellitePass', satellitePass)
            const localVarPath = `/satellite-passes/{satellitePassId}`
                .replace(`{${"satellitePassId"}}`, encodeURIComponent(String(satellitePassId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(satellitePass, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SatellitePassesApi - functional programming interface
 * @export
 */
export const SatellitePassesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SatellitePassesApiAxiosParamCreator(configuration)
    return {
        /**
         * This method cancels a satellite pass by ID for the current user
         * @summary Cancel a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSatellitePass(satellitePassId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSatellitePass(satellitePassId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method is used for booking passes for the current user. It requires a Pass Prediction obtained from the Pass Prediction API to be provided in the body of the request. Pass predictions are valid for 20 minutes.If the prediction has expired or is ivalid, a 400 Bad Request response will be returned.
         * @summary Book a Satellite Pass
         * @param {PassPrediction} passPrediction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSatellitePass(passPrediction: PassPrediction, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSatellitePass(passPrediction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a filtered list of Satellite Passes. Satellite Passes could be filtered by Satellite, Ground Station and Status
         * @summary Get Filtered Satellite Passes
         * @param {string} [satelliteId] Specifies the satellite for which to get Satellite Passes
         * @param {Array<string>} [groundStation] Specifies the Ground Station(s) of the Satellite Passes to get.
         * @param {'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED'} [status] Specifies the Status (or part of the Status) of the Satellite Passes to get
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredSatellitePasses(satelliteId?: string, groundStation?: Array<string>, status?: 'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED', fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SatellitePassesResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredSatellitePasses(satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a pass by ID for the current user
         * @summary Get a Satellite Pass by ID
         * @param {string} satellitePassId ID of the Satellite Pass to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSatellitePassById(satellitePassId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SatellitePass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSatellitePassById(satellitePassId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method records artifacts of the satellite pass
         * @summary Record a Satellite Pass Artifact
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to upload
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordSatellitePassArtifact(satellitePassId: string, artifactType: ArtifactType, payload: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordSatellitePassArtifact(satellitePassId, artifactType, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method records a log of the satellite pass
         * @summary Record a Satellite Pass Log
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordSatellitePassLog(satellitePassId: string, payload: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordSatellitePassLog(satellitePassId, payload, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method updates a satellite pass by ID for the current user
         * @summary Update a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {SatellitePass} satellitePass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSatellitePassById(satellitePassId: string, satellitePass: SatellitePass, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSatellitePassById(satellitePassId, satellitePass, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SatellitePassesApi - factory interface
 * @export
 */
export const SatellitePassesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SatellitePassesApiFp(configuration)
    return {
        /**
         * This method cancels a satellite pass by ID for the current user
         * @summary Cancel a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSatellitePass(satellitePassId: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelSatellitePass(satellitePassId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method is used for booking passes for the current user. It requires a Pass Prediction obtained from the Pass Prediction API to be provided in the body of the request. Pass predictions are valid for 20 minutes.If the prediction has expired or is ivalid, a 400 Bad Request response will be returned.
         * @summary Book a Satellite Pass
         * @param {PassPrediction} passPrediction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellitePass(passPrediction: PassPrediction, options?: any): AxiosPromise<string> {
            return localVarFp.createSatellitePass(passPrediction, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a filtered list of Satellite Passes. Satellite Passes could be filtered by Satellite, Ground Station and Status
         * @summary Get Filtered Satellite Passes
         * @param {string} [satelliteId] Specifies the satellite for which to get Satellite Passes
         * @param {Array<string>} [groundStation] Specifies the Ground Station(s) of the Satellite Passes to get.
         * @param {'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED'} [status] Specifies the Status (or part of the Status) of the Satellite Passes to get
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatellitePasses(satelliteId?: string, groundStation?: Array<string>, status?: 'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED', fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any): AxiosPromise<SatellitePassesResultPage> {
            return localVarFp.getFilteredSatellitePasses(satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a pass by ID for the current user
         * @summary Get a Satellite Pass by ID
         * @param {string} satellitePassId ID of the Satellite Pass to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatellitePassById(satellitePassId: string, options?: any): AxiosPromise<SatellitePass> {
            return localVarFp.getSatellitePassById(satellitePassId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method records artifacts of the satellite pass
         * @summary Record a Satellite Pass Artifact
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to upload
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordSatellitePassArtifact(satellitePassId: string, artifactType: ArtifactType, payload: any, options?: any): AxiosPromise<void> {
            return localVarFp.recordSatellitePassArtifact(satellitePassId, artifactType, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * This method records a log of the satellite pass
         * @summary Record a Satellite Pass Log
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {any} payload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordSatellitePassLog(satellitePassId: string, payload: any, options?: any): AxiosPromise<void> {
            return localVarFp.recordSatellitePassLog(satellitePassId, payload, options).then((request) => request(axios, basePath));
        },
        /**
         * This method updates a satellite pass by ID for the current user
         * @summary Update a Satellite Pass
         * @param {string} satellitePassId ID of the Satellite Pass to update
         * @param {SatellitePass} satellitePass 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatellitePassById(satellitePassId: string, satellitePass: SatellitePass, options?: any): AxiosPromise<void> {
            return localVarFp.updateSatellitePassById(satellitePassId, satellitePass, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SatellitePassesApi - object-oriented interface
 * @export
 * @class SatellitePassesApi
 * @extends {BaseAPI}
 */
export class SatellitePassesApi extends BaseAPI {
    /**
     * This method cancels a satellite pass by ID for the current user
     * @summary Cancel a Satellite Pass
     * @param {string} satellitePassId ID of the Satellite Pass to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    public cancelSatellitePass(satellitePassId: string, options?: any) {
        return SatellitePassesApiFp(this.configuration).cancelSatellitePass(satellitePassId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method is used for booking passes for the current user. It requires a Pass Prediction obtained from the Pass Prediction API to be provided in the body of the request. Pass predictions are valid for 20 minutes.If the prediction has expired or is ivalid, a 400 Bad Request response will be returned.
     * @summary Book a Satellite Pass
     * @param {PassPrediction} passPrediction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    public createSatellitePass(passPrediction: PassPrediction, options?: any) {
        return SatellitePassesApiFp(this.configuration).createSatellitePass(passPrediction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a filtered list of Satellite Passes. Satellite Passes could be filtered by Satellite, Ground Station and Status
     * @summary Get Filtered Satellite Passes
     * @param {string} [satelliteId] Specifies the satellite for which to get Satellite Passes
     * @param {Array<string>} [groundStation] Specifies the Ground Station(s) of the Satellite Passes to get.
     * @param {'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED'} [status] Specifies the Status (or part of the Status) of the Satellite Passes to get
     * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
     * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    public getFilteredSatellitePasses(satelliteId?: string, groundStation?: Array<string>, status?: 'PREDICTION' | 'SCHEDULED' | 'IN_PROGRESS' | 'FINISHED_SUCCESSFULLY' | 'FINISHED_WITH_ERRORS' | 'CANCELED' | 'MISSED', fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, options?: any) {
        return SatellitePassesApiFp(this.configuration).getFilteredSatellitePasses(satelliteId, groundStation, status, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a pass by ID for the current user
     * @summary Get a Satellite Pass by ID
     * @param {string} satellitePassId ID of the Satellite Pass to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    public getSatellitePassById(satellitePassId: string, options?: any) {
        return SatellitePassesApiFp(this.configuration).getSatellitePassById(satellitePassId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method records artifacts of the satellite pass
     * @summary Record a Satellite Pass Artifact
     * @param {string} satellitePassId ID of the Satellite Pass to update
     * @param {ArtifactType} artifactType Specifies the artifact type of the Satellite Passes to upload
     * @param {any} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    public recordSatellitePassArtifact(satellitePassId: string, artifactType: ArtifactType, payload: any, options?: any) {
        return SatellitePassesApiFp(this.configuration).recordSatellitePassArtifact(satellitePassId, artifactType, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method records a log of the satellite pass
     * @summary Record a Satellite Pass Log
     * @param {string} satellitePassId ID of the Satellite Pass to update
     * @param {any} payload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    public recordSatellitePassLog(satellitePassId: string, payload: any, options?: any) {
        return SatellitePassesApiFp(this.configuration).recordSatellitePassLog(satellitePassId, payload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method updates a satellite pass by ID for the current user
     * @summary Update a Satellite Pass
     * @param {string} satellitePassId ID of the Satellite Pass to update
     * @param {SatellitePass} satellitePass 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitePassesApi
     */
    public updateSatellitePassById(satellitePassId: string, satellitePass: SatellitePass, options?: any) {
        return SatellitePassesApiFp(this.configuration).updateSatellitePassById(satellitePassId, satellitePass, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SatelliteSubsystemsApi - axios parameter creator
 * @export
 */
export const SatelliteSubsystemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method creates a new Satellite Subsystem for the current user.
         * @summary Create a Satellite Subsystem
         * @param {SatelliteSubsystem} satelliteSubsystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatelliteSubsystem: async (satelliteSubsystem: SatelliteSubsystem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satelliteSubsystem' is not null or undefined
            assertParamExists('createSatelliteSubsystem', 'satelliteSubsystem', satelliteSubsystem)
            const localVarPath = `/satellite-subsystems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(satelliteSubsystem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method deletes a Satellite Subsystem by ID
         * @summary Delete a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteSubsystemById: async (satelliteSubsystemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satelliteSubsystemId' is not null or undefined
            assertParamExists('deleteSatelliteSubsystemById', 'satelliteSubsystemId', satelliteSubsystemId)
            const localVarPath = `/satellite-subsystems/{satelliteSubsystemId}`
                .replace(`{${"satelliteSubsystemId"}}`, encodeURIComponent(String(satelliteSubsystemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a filtered list of Satellite Subsystems. Satellite Subsystems could be filtered by Satellite, Name and Type. Maximum count of the returned items is 10000.
         * @summary Get all Satellite Subsystems
         * @param {string} [satelliteId] Specifies the Satellite for which the Subsystems are applicable
         * @param {string} [name] Specifies the name (or part of the name) of the Subsystem
         * @param {string} [type] Specifies the type (or part of the type) of the Subsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatelliteSubsystems: async (satelliteId?: string, name?: string, type?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/satellite-subsystems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Satellite Subsystem by ID
         * @summary Get a Satellite Subsystem by ID
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteSubsystemById: async (satelliteSubsystemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satelliteSubsystemId' is not null or undefined
            assertParamExists('getSatelliteSubsystemById', 'satelliteSubsystemId', satelliteSubsystemId)
            const localVarPath = `/satellite-subsystems/{satelliteSubsystemId}`
                .replace(`{${"satelliteSubsystemId"}}`, encodeURIComponent(String(satelliteSubsystemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method updates a Satellite Subsystem by ID
         * @summary Update a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to update
         * @param {SatelliteSubsystem} satelliteSubsystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteSubsystemById: async (satelliteSubsystemId: string, satelliteSubsystem: SatelliteSubsystem, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satelliteSubsystemId' is not null or undefined
            assertParamExists('updateSatelliteSubsystemById', 'satelliteSubsystemId', satelliteSubsystemId)
            // verify required parameter 'satelliteSubsystem' is not null or undefined
            assertParamExists('updateSatelliteSubsystemById', 'satelliteSubsystem', satelliteSubsystem)
            const localVarPath = `/satellite-subsystems/{satelliteSubsystemId}`
                .replace(`{${"satelliteSubsystemId"}}`, encodeURIComponent(String(satelliteSubsystemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(satelliteSubsystem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SatelliteSubsystemsApi - functional programming interface
 * @export
 */
export const SatelliteSubsystemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SatelliteSubsystemsApiAxiosParamCreator(configuration)
    return {
        /**
         * This method creates a new Satellite Subsystem for the current user.
         * @summary Create a Satellite Subsystem
         * @param {SatelliteSubsystem} satelliteSubsystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSatelliteSubsystem(satelliteSubsystem: SatelliteSubsystem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSatelliteSubsystem(satelliteSubsystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method deletes a Satellite Subsystem by ID
         * @summary Delete a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSatelliteSubsystemById(satelliteSubsystemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSatelliteSubsystemById(satelliteSubsystemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a filtered list of Satellite Subsystems. Satellite Subsystems could be filtered by Satellite, Name and Type. Maximum count of the returned items is 10000.
         * @summary Get all Satellite Subsystems
         * @param {string} [satelliteId] Specifies the Satellite for which the Subsystems are applicable
         * @param {string} [name] Specifies the name (or part of the name) of the Subsystem
         * @param {string} [type] Specifies the type (or part of the type) of the Subsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredSatelliteSubsystems(satelliteId?: string, name?: string, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SatelliteSubsystem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredSatelliteSubsystems(satelliteId, name, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Satellite Subsystem by ID
         * @summary Get a Satellite Subsystem by ID
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSatelliteSubsystemById(satelliteSubsystemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SatelliteSubsystem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSatelliteSubsystemById(satelliteSubsystemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method updates a Satellite Subsystem by ID
         * @summary Update a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to update
         * @param {SatelliteSubsystem} satelliteSubsystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSatelliteSubsystemById(satelliteSubsystemId: string, satelliteSubsystem: SatelliteSubsystem, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSatelliteSubsystemById(satelliteSubsystemId, satelliteSubsystem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SatelliteSubsystemsApi - factory interface
 * @export
 */
export const SatelliteSubsystemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SatelliteSubsystemsApiFp(configuration)
    return {
        /**
         * This method creates a new Satellite Subsystem for the current user.
         * @summary Create a Satellite Subsystem
         * @param {SatelliteSubsystem} satelliteSubsystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatelliteSubsystem(satelliteSubsystem: SatelliteSubsystem, options?: any): AxiosPromise<void> {
            return localVarFp.createSatelliteSubsystem(satelliteSubsystem, options).then((request) => request(axios, basePath));
        },
        /**
         * This method deletes a Satellite Subsystem by ID
         * @summary Delete a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteSubsystemById(satelliteSubsystemId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSatelliteSubsystemById(satelliteSubsystemId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a filtered list of Satellite Subsystems. Satellite Subsystems could be filtered by Satellite, Name and Type. Maximum count of the returned items is 10000.
         * @summary Get all Satellite Subsystems
         * @param {string} [satelliteId] Specifies the Satellite for which the Subsystems are applicable
         * @param {string} [name] Specifies the name (or part of the name) of the Subsystem
         * @param {string} [type] Specifies the type (or part of the type) of the Subsystem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredSatelliteSubsystems(satelliteId?: string, name?: string, type?: string, options?: any): AxiosPromise<Array<SatelliteSubsystem>> {
            return localVarFp.getFilteredSatelliteSubsystems(satelliteId, name, type, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Satellite Subsystem by ID
         * @summary Get a Satellite Subsystem by ID
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteSubsystemById(satelliteSubsystemId: string, options?: any): AxiosPromise<SatelliteSubsystem> {
            return localVarFp.getSatelliteSubsystemById(satelliteSubsystemId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method updates a Satellite Subsystem by ID
         * @summary Update a Satellite Subsystem
         * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to update
         * @param {SatelliteSubsystem} satelliteSubsystem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteSubsystemById(satelliteSubsystemId: string, satelliteSubsystem: SatelliteSubsystem, options?: any): AxiosPromise<void> {
            return localVarFp.updateSatelliteSubsystemById(satelliteSubsystemId, satelliteSubsystem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SatelliteSubsystemsApi - object-oriented interface
 * @export
 * @class SatelliteSubsystemsApi
 * @extends {BaseAPI}
 */
export class SatelliteSubsystemsApi extends BaseAPI {
    /**
     * This method creates a new Satellite Subsystem for the current user.
     * @summary Create a Satellite Subsystem
     * @param {SatelliteSubsystem} satelliteSubsystem 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    public createSatelliteSubsystem(satelliteSubsystem: SatelliteSubsystem, options?: any) {
        return SatelliteSubsystemsApiFp(this.configuration).createSatelliteSubsystem(satelliteSubsystem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method deletes a Satellite Subsystem by ID
     * @summary Delete a Satellite Subsystem
     * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    public deleteSatelliteSubsystemById(satelliteSubsystemId: string, options?: any) {
        return SatelliteSubsystemsApiFp(this.configuration).deleteSatelliteSubsystemById(satelliteSubsystemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a filtered list of Satellite Subsystems. Satellite Subsystems could be filtered by Satellite, Name and Type. Maximum count of the returned items is 10000.
     * @summary Get all Satellite Subsystems
     * @param {string} [satelliteId] Specifies the Satellite for which the Subsystems are applicable
     * @param {string} [name] Specifies the name (or part of the name) of the Subsystem
     * @param {string} [type] Specifies the type (or part of the type) of the Subsystem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    public getFilteredSatelliteSubsystems(satelliteId?: string, name?: string, type?: string, options?: any) {
        return SatelliteSubsystemsApiFp(this.configuration).getFilteredSatelliteSubsystems(satelliteId, name, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Satellite Subsystem by ID
     * @summary Get a Satellite Subsystem by ID
     * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    public getSatelliteSubsystemById(satelliteSubsystemId: string, options?: any) {
        return SatelliteSubsystemsApiFp(this.configuration).getSatelliteSubsystemById(satelliteSubsystemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method updates a Satellite Subsystem by ID
     * @summary Update a Satellite Subsystem
     * @param {string} satelliteSubsystemId ID of the Satellite Subsystem to update
     * @param {SatelliteSubsystem} satelliteSubsystem 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteSubsystemsApi
     */
    public updateSatelliteSubsystemById(satelliteSubsystemId: string, satelliteSubsystem: SatelliteSubsystem, options?: any) {
        return SatelliteSubsystemsApiFp(this.configuration).updateSatelliteSubsystemById(satelliteSubsystemId, satelliteSubsystem, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SatelliteTelecommandsApi - axios parameter creator
 * @export
 */
export const SatelliteTelecommandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method creates new satellite telecommands for the current user.
         * @summary Create a Satellite Telecommand
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommand: async (telecommand: Telecommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'telecommand' is not null or undefined
            assertParamExists('createTelecommand', 'telecommand', telecommand)
            const localVarPath = `/satellite-telecommands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(telecommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method creates new satellite telecommands with payloads for the current user.
         * @summary Create a Satellite Telecommand with Payload
         * @param {any} payload 
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommandWithPayload: async (payload: any, telecommand: Telecommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('createTelecommandWithPayload', 'payload', payload)
            // verify required parameter 'telecommand' is not null or undefined
            assertParamExists('createTelecommandWithPayload', 'telecommand', telecommand)
            const localVarPath = `/satellite-telecommands/with-payload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


            if (payload !== undefined) { 
                localVarFormParams.append('payload', payload as any);
            }
    
            if (telecommand !== undefined) { 
                localVarFormParams.append('telecommand', new Blob([JSON.stringify(telecommand)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method deletes a Satellite Telecommand by ID for the current user
         * @summary Delete a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTelecommandById: async (telecommandId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'telecommandId' is not null or undefined
            assertParamExists('deleteTelecommandById', 'telecommandId', telecommandId)
            const localVarPath = `/satellite-telecommands/{telecommandId}`
                .replace(`{${"telecommandId"}}`, encodeURIComponent(String(telecommandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a list of already executed telecommands for a satellite. Telecommands could be filtered by Satellite, FromTime, ToTime based on their execution time.
         * @summary Get Telecommand History
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutedTelecommands: async (satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/satellite-telecommands/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = toTime;
            }

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }

            if (dataQuery !== undefined) {
                localVarQueryParameter['dataQuery'] = dataQuery;
            }

            if (projectionExpression !== undefined) {
                localVarQueryParameter['projectionExpression'] = projectionExpression;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a filtered list of telecommands for a satellite. Telecommands could be filtered by Satellite, Satellite Subsystem, Requested Satellite Pass, Executed Satellite Pass and Status
         * @summary Get Filtered Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsystem) for which to get telecommands
         * @param {string} [name] Specifies the name or part of the name (or part of the name) of the telecommand
         * @param {string} [requestedSatellitePass] Specifies the satellite pass (or part of the pass) for which the telecommand was originally scheduled
         * @param {string} [executedSatellitePass] Specifies the satellite pass (or part of the pass) during which the telecommand was sent to the satellite
         * @param {'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE'} [status] Specifies the status (or part of the status) of the telecommand
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelecommands: async (satelliteId?: string, satelliteSubsystemId?: string, name?: string, requestedSatellitePass?: string, executedSatellitePass?: string, status?: 'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE', lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/satellite-telecommands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (satelliteSubsystemId !== undefined) {
                localVarQueryParameter['satelliteSubsystemId'] = satelliteSubsystemId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (requestedSatellitePass !== undefined) {
                localVarQueryParameter['requestedSatellitePass'] = requestedSatellitePass;
            }

            if (executedSatellitePass !== undefined) {
                localVarQueryParameter['executedSatellitePass'] = executedSatellitePass;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }

            if (dataQuery !== undefined) {
                localVarQueryParameter['dataQuery'] = dataQuery;
            }

            if (projectionExpression !== undefined) {
                localVarQueryParameter['projectionExpression'] = projectionExpression;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a list of regular telecommands for a satellite that are not executed yet. Telecommands could be filtered by Satellite, FromTime, ToTime based on their requested pass AOS.
         * @summary Get Regular Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegularTelecommands: async (satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/satellite-telecommands/pending-regular`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = toTime;
            }

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }

            if (dataQuery !== undefined) {
                localVarQueryParameter['dataQuery'] = dataQuery;
            }

            if (projectionExpression !== undefined) {
                localVarQueryParameter['projectionExpression'] = projectionExpression;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Satellite Telecommand by ID for the current user
         * @summary Get a Satellite Telecommand by ID
         * @param {string} telecommandId ID of the Satellite Telecommand to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelecommandById: async (telecommandId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'telecommandId' is not null or undefined
            assertParamExists('getTelecommandById', 'telecommandId', telecommandId)
            const localVarPath = `/satellite-telecommands/{telecommandId}`
                .replace(`{${"telecommandId"}}`, encodeURIComponent(String(telecommandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a list of time tagged telecommands for a satellite that are not executed yet. Telecommands could be filtered by Satellite, FromTime, ToTime based on their start of satellite command execution.
         * @summary Get TimeTagged Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeTaggedTelecommands: async (satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/satellite-telecommands/pending-time-tagged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = toTime;
            }

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }

            if (dataQuery !== undefined) {
                localVarQueryParameter['dataQuery'] = dataQuery;
            }

            if (projectionExpression !== undefined) {
                localVarQueryParameter['projectionExpression'] = projectionExpression;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method updates a Satellite Telecommand by ID for the current user
         * @summary Update a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to update
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelecommandById: async (telecommandId: string, telecommand: Telecommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'telecommandId' is not null or undefined
            assertParamExists('updateTelecommandById', 'telecommandId', telecommandId)
            // verify required parameter 'telecommand' is not null or undefined
            assertParamExists('updateTelecommandById', 'telecommand', telecommand)
            const localVarPath = `/satellite-telecommands/{telecommandId}`
                .replace(`{${"telecommandId"}}`, encodeURIComponent(String(telecommandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(telecommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SatelliteTelecommandsApi - functional programming interface
 * @export
 */
export const SatelliteTelecommandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SatelliteTelecommandsApiAxiosParamCreator(configuration)
    return {
        /**
         * This method creates new satellite telecommands for the current user.
         * @summary Create a Satellite Telecommand
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTelecommand(telecommand: Telecommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTelecommand(telecommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method creates new satellite telecommands with payloads for the current user.
         * @summary Create a Satellite Telecommand with Payload
         * @param {any} payload 
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTelecommandWithPayload(payload: any, telecommand: Telecommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTelecommandWithPayload(payload, telecommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method deletes a Satellite Telecommand by ID for the current user
         * @summary Delete a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTelecommandById(telecommandId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTelecommandById(telecommandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a list of already executed telecommands for a satellite. Telecommands could be filtered by Satellite, FromTime, ToTime based on their execution time.
         * @summary Get Telecommand History
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExecutedTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelecommandsResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExecutedTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a filtered list of telecommands for a satellite. Telecommands could be filtered by Satellite, Satellite Subsystem, Requested Satellite Pass, Executed Satellite Pass and Status
         * @summary Get Filtered Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsystem) for which to get telecommands
         * @param {string} [name] Specifies the name or part of the name (or part of the name) of the telecommand
         * @param {string} [requestedSatellitePass] Specifies the satellite pass (or part of the pass) for which the telecommand was originally scheduled
         * @param {string} [executedSatellitePass] Specifies the satellite pass (or part of the pass) during which the telecommand was sent to the satellite
         * @param {'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE'} [status] Specifies the status (or part of the status) of the telecommand
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredTelecommands(satelliteId?: string, satelliteSubsystemId?: string, name?: string, requestedSatellitePass?: string, executedSatellitePass?: string, status?: 'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE', lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelecommandsResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredTelecommands(satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a list of regular telecommands for a satellite that are not executed yet. Telecommands could be filtered by Satellite, FromTime, ToTime based on their requested pass AOS.
         * @summary Get Regular Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegularTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelecommandsResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegularTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Satellite Telecommand by ID for the current user
         * @summary Get a Satellite Telecommand by ID
         * @param {string} telecommandId ID of the Satellite Telecommand to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTelecommandById(telecommandId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Telecommand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTelecommandById(telecommandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a list of time tagged telecommands for a satellite that are not executed yet. Telecommands could be filtered by Satellite, FromTime, ToTime based on their start of satellite command execution.
         * @summary Get TimeTagged Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeTaggedTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelecommandsResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimeTaggedTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method updates a Satellite Telecommand by ID for the current user
         * @summary Update a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to update
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTelecommandById(telecommandId: string, telecommand: Telecommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTelecommandById(telecommandId, telecommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SatelliteTelecommandsApi - factory interface
 * @export
 */
export const SatelliteTelecommandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SatelliteTelecommandsApiFp(configuration)
    return {
        /**
         * This method creates new satellite telecommands for the current user.
         * @summary Create a Satellite Telecommand
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommand(telecommand: Telecommand, options?: any): AxiosPromise<string> {
            return localVarFp.createTelecommand(telecommand, options).then((request) => request(axios, basePath));
        },
        /**
         * This method creates new satellite telecommands with payloads for the current user.
         * @summary Create a Satellite Telecommand with Payload
         * @param {any} payload 
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTelecommandWithPayload(payload: any, telecommand: Telecommand, options?: any): AxiosPromise<string> {
            return localVarFp.createTelecommandWithPayload(payload, telecommand, options).then((request) => request(axios, basePath));
        },
        /**
         * This method deletes a Satellite Telecommand by ID for the current user
         * @summary Delete a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTelecommandById(telecommandId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTelecommandById(telecommandId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a list of already executed telecommands for a satellite. Telecommands could be filtered by Satellite, FromTime, ToTime based on their execution time.
         * @summary Get Telecommand History
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExecutedTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): AxiosPromise<TelecommandsResultPage> {
            return localVarFp.getExecutedTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a filtered list of telecommands for a satellite. Telecommands could be filtered by Satellite, Satellite Subsystem, Requested Satellite Pass, Executed Satellite Pass and Status
         * @summary Get Filtered Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsystem) for which to get telecommands
         * @param {string} [name] Specifies the name or part of the name (or part of the name) of the telecommand
         * @param {string} [requestedSatellitePass] Specifies the satellite pass (or part of the pass) for which the telecommand was originally scheduled
         * @param {string} [executedSatellitePass] Specifies the satellite pass (or part of the pass) during which the telecommand was sent to the satellite
         * @param {'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE'} [status] Specifies the status (or part of the status) of the telecommand
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelecommands(satelliteId?: string, satelliteSubsystemId?: string, name?: string, requestedSatellitePass?: string, executedSatellitePass?: string, status?: 'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE', lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): AxiosPromise<TelecommandsResultPage> {
            return localVarFp.getFilteredTelecommands(satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a list of regular telecommands for a satellite that are not executed yet. Telecommands could be filtered by Satellite, FromTime, ToTime based on their requested pass AOS.
         * @summary Get Regular Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegularTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): AxiosPromise<TelecommandsResultPage> {
            return localVarFp.getRegularTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Satellite Telecommand by ID for the current user
         * @summary Get a Satellite Telecommand by ID
         * @param {string} telecommandId ID of the Satellite Telecommand to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelecommandById(telecommandId: string, options?: any): AxiosPromise<Telecommand> {
            return localVarFp.getTelecommandById(telecommandId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a list of time tagged telecommands for a satellite that are not executed yet. Telecommands could be filtered by Satellite, FromTime, ToTime based on their start of satellite command execution.
         * @summary Get TimeTagged Telecommands
         * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeTaggedTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): AxiosPromise<TelecommandsResultPage> {
            return localVarFp.getTimeTaggedTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(axios, basePath));
        },
        /**
         * This method updates a Satellite Telecommand by ID for the current user
         * @summary Update a Satellite Telecommand
         * @param {string} telecommandId ID of the Satellite Telecommand to update
         * @param {Telecommand} telecommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelecommandById(telecommandId: string, telecommand: Telecommand, options?: any): AxiosPromise<void> {
            return localVarFp.updateTelecommandById(telecommandId, telecommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SatelliteTelecommandsApi - object-oriented interface
 * @export
 * @class SatelliteTelecommandsApi
 * @extends {BaseAPI}
 */
export class SatelliteTelecommandsApi extends BaseAPI {
    /**
     * This method creates new satellite telecommands for the current user.
     * @summary Create a Satellite Telecommand
     * @param {Telecommand} telecommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public createTelecommand(telecommand: Telecommand, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).createTelecommand(telecommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method creates new satellite telecommands with payloads for the current user.
     * @summary Create a Satellite Telecommand with Payload
     * @param {any} payload 
     * @param {Telecommand} telecommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public createTelecommandWithPayload(payload: any, telecommand: Telecommand, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).createTelecommandWithPayload(payload, telecommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method deletes a Satellite Telecommand by ID for the current user
     * @summary Delete a Satellite Telecommand
     * @param {string} telecommandId ID of the Satellite Telecommand to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public deleteTelecommandById(telecommandId: string, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).deleteTelecommandById(telecommandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a list of already executed telecommands for a satellite. Telecommands could be filtered by Satellite, FromTime, ToTime based on their execution time.
     * @summary Get Telecommand History
     * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
     * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
     * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
     * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public getExecutedTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).getExecutedTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a filtered list of telecommands for a satellite. Telecommands could be filtered by Satellite, Satellite Subsystem, Requested Satellite Pass, Executed Satellite Pass and Status
     * @summary Get Filtered Telecommands
     * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
     * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsystem) for which to get telecommands
     * @param {string} [name] Specifies the name or part of the name (or part of the name) of the telecommand
     * @param {string} [requestedSatellitePass] Specifies the satellite pass (or part of the pass) for which the telecommand was originally scheduled
     * @param {string} [executedSatellitePass] Specifies the satellite pass (or part of the pass) during which the telecommand was sent to the satellite
     * @param {'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE'} [status] Specifies the status (or part of the status) of the telecommand
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
     * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public getFilteredTelecommands(satelliteId?: string, satelliteSubsystemId?: string, name?: string, requestedSatellitePass?: string, executedSatellitePass?: string, status?: 'DRAFT' | 'SCHEDULED' | 'REQUESTED' | 'TESTED' | 'SENDING' | 'SENT' | 'FAILED_TO_SEND' | 'RECEIVED' | 'EXECUTING' | 'SUCCESSFUL' | 'FAILED' | 'CANCELED' | 'TEMPLATE', lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).getFilteredTelecommands(satelliteId, satelliteSubsystemId, name, requestedSatellitePass, executedSatellitePass, status, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a list of regular telecommands for a satellite that are not executed yet. Telecommands could be filtered by Satellite, FromTime, ToTime based on their requested pass AOS.
     * @summary Get Regular Telecommands
     * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
     * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
     * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
     * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public getRegularTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).getRegularTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Satellite Telecommand by ID for the current user
     * @summary Get a Satellite Telecommand by ID
     * @param {string} telecommandId ID of the Satellite Telecommand to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public getTelecommandById(telecommandId: string, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).getTelecommandById(telecommandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a list of time tagged telecommands for a satellite that are not executed yet. Telecommands could be filtered by Satellite, FromTime, ToTime based on their start of satellite command execution.
     * @summary Get TimeTagged Telecommands
     * @param {string} [satelliteId] Specifies the satellite for which to get telecommands
     * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
     * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
     * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public getTimeTaggedTelecommands(satelliteId?: string, fromTime?: number, toTime?: number, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).getTimeTaggedTelecommands(satelliteId, fromTime, toTime, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method updates a Satellite Telecommand by ID for the current user
     * @summary Update a Satellite Telecommand
     * @param {string} telecommandId ID of the Satellite Telecommand to update
     * @param {Telecommand} telecommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelecommandsApi
     */
    public updateTelecommandById(telecommandId: string, telecommand: Telecommand, options?: any) {
        return SatelliteTelecommandsApiFp(this.configuration).updateTelecommandById(telecommandId, telecommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SatelliteTelemetryApi - axios parameter creator
 * @export
 */
export const SatelliteTelemetryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method returns a filtered list of historical telemetry values for a satellite. Telemetry could be filtered by Satellite, Satellite Pass and time received
         * @summary Get Historical Telemetry
         * @param {string} [name] Specifies the name (or part of the name) of the telemetry
         * @param {string} [satelliteId] Specifies the satellite for which to get telemetry
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsytem) for which to get telemetry
         * @param {string} [passId] Specifies the satellite pass (or part of the pass) during which telemetry was aquired
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [missionDatabase] Filter by mission database (or part of the mission database)
         * @param {string} [telecommandRequest] Filter by telecommand request
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelemetryEntries: async (name?: string, satelliteId?: string, satelliteSubsystemId?: string, passId?: string, fromTime?: number, toTime?: number, missionDatabase?: string, telecommandRequest?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/satellite-telemetry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (satelliteId !== undefined) {
                localVarQueryParameter['satelliteId'] = satelliteId;
            }

            if (satelliteSubsystemId !== undefined) {
                localVarQueryParameter['satelliteSubsystemId'] = satelliteSubsystemId;
            }

            if (passId !== undefined) {
                localVarQueryParameter['passId'] = passId;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = toTime;
            }

            if (missionDatabase !== undefined) {
                localVarQueryParameter['missionDatabase'] = missionDatabase;
            }

            if (telecommandRequest !== undefined) {
                localVarQueryParameter['telecommandRequest'] = telecommandRequest;
            }

            if (lastEvaluatedItem !== undefined) {
                localVarQueryParameter['lastEvaluatedItem'] = lastEvaluatedItem;
            }

            if (pageSizeLimit !== undefined) {
                localVarQueryParameter['pageSizeLimit'] = pageSizeLimit;
            }

            if (dataQuery !== undefined) {
                localVarQueryParameter['dataQuery'] = dataQuery;
            }

            if (projectionExpression !== undefined) {
                localVarQueryParameter['projectionExpression'] = projectionExpression;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Satellite Telemetry Entry by ID for the current user
         * @summary Get a Satellite Telemetry Entry by ID
         * @param {string} telemetryEntryId ID of the Satellite Telemetry Entry to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelemetryEntryById: async (telemetryEntryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'telemetryEntryId' is not null or undefined
            assertParamExists('getTelemetryEntryById', 'telemetryEntryId', telemetryEntryId)
            const localVarPath = `/satellite-telemetry/{telemetryEntryId}`
                .replace(`{${"telemetryEntryId"}}`, encodeURIComponent(String(telemetryEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method is used for recording batch telemetry for a satellite.
         * @summary Record Batch Telemetry
         * @param {Array<TelemetryEntry>} telemetryEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordBatchTelemetry: async (telemetryEntry: Array<TelemetryEntry>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'telemetryEntry' is not null or undefined
            assertParamExists('recordBatchTelemetry', 'telemetryEntry', telemetryEntry)
            const localVarPath = `/satellite-telemetry/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(telemetryEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method is used for recording telemetry with a payload for a satellite.
         * @summary Record Telemetry with Payload
         * @param {any} payload 
         * @param {TelemetryEntry} telemetry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordTelemetryWithPayload: async (payload: any, telemetry: TelemetryEntry, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payload' is not null or undefined
            assertParamExists('recordTelemetryWithPayload', 'payload', payload)
            // verify required parameter 'telemetry' is not null or undefined
            assertParamExists('recordTelemetryWithPayload', 'telemetry', telemetry)
            const localVarPath = `/satellite-telemetry/with-payload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


            if (payload !== undefined) { 
                localVarFormParams.append('payload', payload as any);
            }
    
            if (telemetry !== undefined) { 
                localVarFormParams.append('telemetry', new Blob([JSON.stringify(telemetry)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method updates a Telemetry Entry by ID
         * @summary Update a Telemetry Entry
         * @param {string} telemetryEntryId ID of the Telemetry Entry to update
         * @param {TelemetryEntry} telemetryEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelemetryEntryById: async (telemetryEntryId: string, telemetryEntry: TelemetryEntry, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'telemetryEntryId' is not null or undefined
            assertParamExists('updateTelemetryEntryById', 'telemetryEntryId', telemetryEntryId)
            // verify required parameter 'telemetryEntry' is not null or undefined
            assertParamExists('updateTelemetryEntryById', 'telemetryEntry', telemetryEntry)
            const localVarPath = `/satellite-telemetry/{telemetryEntryId}`
                .replace(`{${"telemetryEntryId"}}`, encodeURIComponent(String(telemetryEntryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(telemetryEntry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SatelliteTelemetryApi - functional programming interface
 * @export
 */
export const SatelliteTelemetryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SatelliteTelemetryApiAxiosParamCreator(configuration)
    return {
        /**
         * This method returns a filtered list of historical telemetry values for a satellite. Telemetry could be filtered by Satellite, Satellite Pass and time received
         * @summary Get Historical Telemetry
         * @param {string} [name] Specifies the name (or part of the name) of the telemetry
         * @param {string} [satelliteId] Specifies the satellite for which to get telemetry
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsytem) for which to get telemetry
         * @param {string} [passId] Specifies the satellite pass (or part of the pass) during which telemetry was aquired
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [missionDatabase] Filter by mission database (or part of the mission database)
         * @param {string} [telecommandRequest] Filter by telecommand request
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilteredTelemetryEntries(name?: string, satelliteId?: string, satelliteSubsystemId?: string, passId?: string, fromTime?: number, toTime?: number, missionDatabase?: string, telecommandRequest?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryResultPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredTelemetryEntries(name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Satellite Telemetry Entry by ID for the current user
         * @summary Get a Satellite Telemetry Entry by ID
         * @param {string} telemetryEntryId ID of the Satellite Telemetry Entry to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTelemetryEntryById(telemetryEntryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTelemetryEntryById(telemetryEntryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method is used for recording batch telemetry for a satellite.
         * @summary Record Batch Telemetry
         * @param {Array<TelemetryEntry>} telemetryEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordBatchTelemetry(telemetryEntry: Array<TelemetryEntry>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordBatchTelemetry(telemetryEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method is used for recording telemetry with a payload for a satellite.
         * @summary Record Telemetry with Payload
         * @param {any} payload 
         * @param {TelemetryEntry} telemetry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordTelemetryWithPayload(payload: any, telemetry: TelemetryEntry, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordTelemetryWithPayload(payload, telemetry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method updates a Telemetry Entry by ID
         * @summary Update a Telemetry Entry
         * @param {string} telemetryEntryId ID of the Telemetry Entry to update
         * @param {TelemetryEntry} telemetryEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTelemetryEntryById(telemetryEntryId: string, telemetryEntry: TelemetryEntry, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTelemetryEntryById(telemetryEntryId, telemetryEntry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SatelliteTelemetryApi - factory interface
 * @export
 */
export const SatelliteTelemetryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SatelliteTelemetryApiFp(configuration)
    return {
        /**
         * This method returns a filtered list of historical telemetry values for a satellite. Telemetry could be filtered by Satellite, Satellite Pass and time received
         * @summary Get Historical Telemetry
         * @param {string} [name] Specifies the name (or part of the name) of the telemetry
         * @param {string} [satelliteId] Specifies the satellite for which to get telemetry
         * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsytem) for which to get telemetry
         * @param {string} [passId] Specifies the satellite pass (or part of the pass) during which telemetry was aquired
         * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
         * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
         * @param {string} [missionDatabase] Filter by mission database (or part of the mission database)
         * @param {string} [telecommandRequest] Filter by telecommand request
         * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
         * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
         * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
         * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilteredTelemetryEntries(name?: string, satelliteId?: string, satelliteSubsystemId?: string, passId?: string, fromTime?: number, toTime?: number, missionDatabase?: string, telecommandRequest?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any): AxiosPromise<TelemetryResultPage> {
            return localVarFp.getFilteredTelemetryEntries(name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Satellite Telemetry Entry by ID for the current user
         * @summary Get a Satellite Telemetry Entry by ID
         * @param {string} telemetryEntryId ID of the Satellite Telemetry Entry to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTelemetryEntryById(telemetryEntryId: string, options?: any): AxiosPromise<TelemetryEntry> {
            return localVarFp.getTelemetryEntryById(telemetryEntryId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method is used for recording batch telemetry for a satellite.
         * @summary Record Batch Telemetry
         * @param {Array<TelemetryEntry>} telemetryEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordBatchTelemetry(telemetryEntry: Array<TelemetryEntry>, options?: any): AxiosPromise<void> {
            return localVarFp.recordBatchTelemetry(telemetryEntry, options).then((request) => request(axios, basePath));
        },
        /**
         * This method is used for recording telemetry with a payload for a satellite.
         * @summary Record Telemetry with Payload
         * @param {any} payload 
         * @param {TelemetryEntry} telemetry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordTelemetryWithPayload(payload: any, telemetry: TelemetryEntry, options?: any): AxiosPromise<void> {
            return localVarFp.recordTelemetryWithPayload(payload, telemetry, options).then((request) => request(axios, basePath));
        },
        /**
         * This method updates a Telemetry Entry by ID
         * @summary Update a Telemetry Entry
         * @param {string} telemetryEntryId ID of the Telemetry Entry to update
         * @param {TelemetryEntry} telemetryEntry 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTelemetryEntryById(telemetryEntryId: string, telemetryEntry: TelemetryEntry, options?: any): AxiosPromise<void> {
            return localVarFp.updateTelemetryEntryById(telemetryEntryId, telemetryEntry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SatelliteTelemetryApi - object-oriented interface
 * @export
 * @class SatelliteTelemetryApi
 * @extends {BaseAPI}
 */
export class SatelliteTelemetryApi extends BaseAPI {
    /**
     * This method returns a filtered list of historical telemetry values for a satellite. Telemetry could be filtered by Satellite, Satellite Pass and time received
     * @summary Get Historical Telemetry
     * @param {string} [name] Specifies the name (or part of the name) of the telemetry
     * @param {string} [satelliteId] Specifies the satellite for which to get telemetry
     * @param {string} [satelliteSubsystemId] Specifies the satellite subsystem (or part of the subsytem) for which to get telemetry
     * @param {string} [passId] Specifies the satellite pass (or part of the pass) during which telemetry was aquired
     * @param {number} [fromTime] Filter to only return entries that were received after the specified time (inclusive)
     * @param {number} [toTime] Filter to only return entries that were received before the specified time (exclusive)
     * @param {string} [missionDatabase] Filter by mission database (or part of the mission database)
     * @param {string} [telecommandRequest] Filter by telecommand request
     * @param {string} [lastEvaluatedItem] Specified the ID of the last item that was returned from the last page
     * @param {number} [pageSizeLimit] Specifies the maximum number of results per page. Defaults to 100
     * @param {string} [dataQuery] Allows you to filter the data field using a query that supports the following comparison operations: \&#39;&gt;\&#39; (greater than), \&#39;&#x3D;&#x3D;\&#39;(equal to), \&#39;&lt;\&#39;(less than), \&#39;&gt;&#x3D;\&#39; (greater than or equal to), \&#39;&lt;&#x3D;\&#39; (less than or equal to). Supported logical operators are \&#39;and\&#39; and \&#39;or\&#39;. Fields and values are case-sensitive. Example: fpHeader.SatId &#x3D;&#x3D; 1 and (fpHeader.FuncId &#x3D;&#x3D; 2 or fpHeader.FuncId &#x3D;&#x3D; 3)
     * @param {string} [projectionExpression] Allows you to specify a subset of attributes to be returned. By default, all attributes are returned. Projection expression supports all properties of the data field. Example: fpHeader.SatId, fpHeader.FuncId, data.types[0]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    public getFilteredTelemetryEntries(name?: string, satelliteId?: string, satelliteSubsystemId?: string, passId?: string, fromTime?: number, toTime?: number, missionDatabase?: string, telecommandRequest?: string, lastEvaluatedItem?: string, pageSizeLimit?: number, dataQuery?: string, projectionExpression?: string, options?: any) {
        return SatelliteTelemetryApiFp(this.configuration).getFilteredTelemetryEntries(name, satelliteId, satelliteSubsystemId, passId, fromTime, toTime, missionDatabase, telecommandRequest, lastEvaluatedItem, pageSizeLimit, dataQuery, projectionExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Satellite Telemetry Entry by ID for the current user
     * @summary Get a Satellite Telemetry Entry by ID
     * @param {string} telemetryEntryId ID of the Satellite Telemetry Entry to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    public getTelemetryEntryById(telemetryEntryId: string, options?: any) {
        return SatelliteTelemetryApiFp(this.configuration).getTelemetryEntryById(telemetryEntryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method is used for recording batch telemetry for a satellite.
     * @summary Record Batch Telemetry
     * @param {Array<TelemetryEntry>} telemetryEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    public recordBatchTelemetry(telemetryEntry: Array<TelemetryEntry>, options?: any) {
        return SatelliteTelemetryApiFp(this.configuration).recordBatchTelemetry(telemetryEntry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method is used for recording telemetry with a payload for a satellite.
     * @summary Record Telemetry with Payload
     * @param {any} payload 
     * @param {TelemetryEntry} telemetry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    public recordTelemetryWithPayload(payload: any, telemetry: TelemetryEntry, options?: any) {
        return SatelliteTelemetryApiFp(this.configuration).recordTelemetryWithPayload(payload, telemetry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method updates a Telemetry Entry by ID
     * @summary Update a Telemetry Entry
     * @param {string} telemetryEntryId ID of the Telemetry Entry to update
     * @param {TelemetryEntry} telemetryEntry 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatelliteTelemetryApi
     */
    public updateTelemetryEntryById(telemetryEntryId: string, telemetryEntry: TelemetryEntry, options?: any) {
        return SatelliteTelemetryApiFp(this.configuration).updateTelemetryEntryById(telemetryEntryId, telemetryEntry, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SatellitesApi - axios parameter creator
 * @export
 */
export const SatellitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method creates a new satellite for the current user.
         * @summary Create a Satellite
         * @param {Satellite} satellite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellite: async (satellite: Satellite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satellite' is not null or undefined
            assertParamExists('createSatellite', 'satellite', satellite)
            const localVarPath = `/satellites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(satellite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method deletes a Satellite by ID
         * @summary Delete a Satellite
         * @param {string} satelliteId ID of the Satellite to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteById: async (satelliteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satelliteId' is not null or undefined
            assertParamExists('deleteSatelliteById', 'satelliteId', satelliteId)
            const localVarPath = `/satellites/{satelliteId}`
                .replace(`{${"satelliteId"}}`, encodeURIComponent(String(satelliteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a list of all Satellites available for the user
         * @summary Get all Satellites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSatellites: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/satellites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns a Satellite by ID
         * @summary Get a Satellite by ID
         * @param {string} satelliteId ID of the Satellite to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteById: async (satelliteId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satelliteId' is not null or undefined
            assertParamExists('getSatelliteById', 'satelliteId', satelliteId)
            const localVarPath = `/satellites/{satelliteId}`
                .replace(`{${"satelliteId"}}`, encodeURIComponent(String(satelliteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method updates a Satellite by ID
         * @summary Update a Satellite
         * @param {string} satelliteId ID of the Satellite to update
         * @param {Satellite} satellite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteById: async (satelliteId: string, satellite: Satellite, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'satelliteId' is not null or undefined
            assertParamExists('updateSatelliteById', 'satelliteId', satelliteId)
            // verify required parameter 'satellite' is not null or undefined
            assertParamExists('updateSatelliteById', 'satellite', satellite)
            const localVarPath = `/satellites/{satelliteId}`
                .replace(`{${"satelliteId"}}`, encodeURIComponent(String(satelliteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(satellite, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SatellitesApi - functional programming interface
 * @export
 */
export const SatellitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SatellitesApiAxiosParamCreator(configuration)
    return {
        /**
         * This method creates a new satellite for the current user.
         * @summary Create a Satellite
         * @param {Satellite} satellite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSatellite(satellite: Satellite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSatellite(satellite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method deletes a Satellite by ID
         * @summary Delete a Satellite
         * @param {string} satelliteId ID of the Satellite to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSatelliteById(satelliteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSatelliteById(satelliteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a list of all Satellites available for the user
         * @summary Get all Satellites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSatellites(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Satellite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSatellites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns a Satellite by ID
         * @summary Get a Satellite by ID
         * @param {string} satelliteId ID of the Satellite to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSatelliteById(satelliteId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Satellite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSatelliteById(satelliteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method updates a Satellite by ID
         * @summary Update a Satellite
         * @param {string} satelliteId ID of the Satellite to update
         * @param {Satellite} satellite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSatelliteById(satelliteId: string, satellite: Satellite, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSatelliteById(satelliteId, satellite, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SatellitesApi - factory interface
 * @export
 */
export const SatellitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SatellitesApiFp(configuration)
    return {
        /**
         * This method creates a new satellite for the current user.
         * @summary Create a Satellite
         * @param {Satellite} satellite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSatellite(satellite: Satellite, options?: any): AxiosPromise<void> {
            return localVarFp.createSatellite(satellite, options).then((request) => request(axios, basePath));
        },
        /**
         * This method deletes a Satellite by ID
         * @summary Delete a Satellite
         * @param {string} satelliteId ID of the Satellite to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSatelliteById(satelliteId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSatelliteById(satelliteId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a list of all Satellites available for the user
         * @summary Get all Satellites
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSatellites(options?: any): AxiosPromise<Array<Satellite>> {
            return localVarFp.getAllSatellites(options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns a Satellite by ID
         * @summary Get a Satellite by ID
         * @param {string} satelliteId ID of the Satellite to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSatelliteById(satelliteId: string, options?: any): AxiosPromise<Satellite> {
            return localVarFp.getSatelliteById(satelliteId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method updates a Satellite by ID
         * @summary Update a Satellite
         * @param {string} satelliteId ID of the Satellite to update
         * @param {Satellite} satellite 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSatelliteById(satelliteId: string, satellite: Satellite, options?: any): AxiosPromise<void> {
            return localVarFp.updateSatelliteById(satelliteId, satellite, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SatellitesApi - object-oriented interface
 * @export
 * @class SatellitesApi
 * @extends {BaseAPI}
 */
export class SatellitesApi extends BaseAPI {
    /**
     * This method creates a new satellite for the current user.
     * @summary Create a Satellite
     * @param {Satellite} satellite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    public createSatellite(satellite: Satellite, options?: any) {
        return SatellitesApiFp(this.configuration).createSatellite(satellite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method deletes a Satellite by ID
     * @summary Delete a Satellite
     * @param {string} satelliteId ID of the Satellite to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    public deleteSatelliteById(satelliteId: string, options?: any) {
        return SatellitesApiFp(this.configuration).deleteSatelliteById(satelliteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a list of all Satellites available for the user
     * @summary Get all Satellites
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    public getAllSatellites(options?: any) {
        return SatellitesApiFp(this.configuration).getAllSatellites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns a Satellite by ID
     * @summary Get a Satellite by ID
     * @param {string} satelliteId ID of the Satellite to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    public getSatelliteById(satelliteId: string, options?: any) {
        return SatellitesApiFp(this.configuration).getSatelliteById(satelliteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method updates a Satellite by ID
     * @summary Update a Satellite
     * @param {string} satelliteId ID of the Satellite to update
     * @param {Satellite} satellite 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SatellitesApi
     */
    public updateSatelliteById(satelliteId: string, satellite: Satellite, options?: any) {
        return SatellitesApiFp(this.configuration).updateSatelliteById(satelliteId, satellite, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserDataApi - axios parameter creator
 * @export
 */
export const UserDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method deletes user data connected to a key
         * @summary Delete User Data
         * @param {string} userDataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDataWithKey: async (userDataId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDataId' is not null or undefined
            assertParamExists('deleteUserDataWithKey', 'userDataId', userDataId)
            const localVarPath = `/user-data/{userDataId}`
                .replace(`{${"userDataId"}}`, encodeURIComponent(String(userDataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method changes an existing record with a key
         * @summary Edit User Data
         * @param {string} userDataId 
         * @param {UserDataRecord} userDataRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserDataWithKey: async (userDataId: string, userDataRecord: UserDataRecord, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDataId' is not null or undefined
            assertParamExists('editUserDataWithKey', 'userDataId', userDataId)
            // verify required parameter 'userDataRecord' is not null or undefined
            assertParamExists('editUserDataWithKey', 'userDataRecord', userDataRecord)
            const localVarPath = `/user-data/{userDataId}`
                .replace(`{${"userDataId"}}`, encodeURIComponent(String(userDataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDataRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns User Data from a key
         * @summary Get User Data
         * @param {string} userDataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataByKey: async (userDataId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userDataId' is not null or undefined
            assertParamExists('getUserDataByKey', 'userDataId', userDataId)
            const localVarPath = `/user-data/{userDataId}`
                .replace(`{${"userDataId"}}`, encodeURIComponent(String(userDataId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication MyEnduroSat required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "MyEnduroSat", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserDataApi - functional programming interface
 * @export
 */
export const UserDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserDataApiAxiosParamCreator(configuration)
    return {
        /**
         * This method deletes user data connected to a key
         * @summary Delete User Data
         * @param {string} userDataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserDataWithKey(userDataId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserDataWithKey(userDataId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method changes an existing record with a key
         * @summary Edit User Data
         * @param {string} userDataId 
         * @param {UserDataRecord} userDataRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editUserDataWithKey(userDataId: string, userDataRecord: UserDataRecord, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editUserDataWithKey(userDataId, userDataRecord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This method returns User Data from a key
         * @summary Get User Data
         * @param {string} userDataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDataByKey(userDataId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDataRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDataByKey(userDataId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserDataApi - factory interface
 * @export
 */
export const UserDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserDataApiFp(configuration)
    return {
        /**
         * This method deletes user data connected to a key
         * @summary Delete User Data
         * @param {string} userDataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDataWithKey(userDataId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserDataWithKey(userDataId, options).then((request) => request(axios, basePath));
        },
        /**
         * This method changes an existing record with a key
         * @summary Edit User Data
         * @param {string} userDataId 
         * @param {UserDataRecord} userDataRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editUserDataWithKey(userDataId: string, userDataRecord: UserDataRecord, options?: any): AxiosPromise<void> {
            return localVarFp.editUserDataWithKey(userDataId, userDataRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * This method returns User Data from a key
         * @summary Get User Data
         * @param {string} userDataId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDataByKey(userDataId: string, options?: any): AxiosPromise<UserDataRecord> {
            return localVarFp.getUserDataByKey(userDataId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserDataApi - object-oriented interface
 * @export
 * @class UserDataApi
 * @extends {BaseAPI}
 */
export class UserDataApi extends BaseAPI {
    /**
     * This method deletes user data connected to a key
     * @summary Delete User Data
     * @param {string} userDataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataApi
     */
    public deleteUserDataWithKey(userDataId: string, options?: any) {
        return UserDataApiFp(this.configuration).deleteUserDataWithKey(userDataId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method changes an existing record with a key
     * @summary Edit User Data
     * @param {string} userDataId 
     * @param {UserDataRecord} userDataRecord 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataApi
     */
    public editUserDataWithKey(userDataId: string, userDataRecord: UserDataRecord, options?: any) {
        return UserDataApiFp(this.configuration).editUserDataWithKey(userDataId, userDataRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This method returns User Data from a key
     * @summary Get User Data
     * @param {string} userDataId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserDataApi
     */
    public getUserDataByKey(userDataId: string, options?: any) {
        return UserDataApiFp(this.configuration).getUserDataByKey(userDataId, options).then((request) => request(this.axios, this.basePath));
    }
}


