import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import AuthService from '../../services/authentication.service'

interface IProps {
    component: React.LazyExoticComponent<() => JSX.Element>
    path: string
    // any other props that come into the component
}

const ProtectedRoute = ({ component: Component, ...rest }: IProps) => {
    const [state, setState] = useState('loading')

    useEffect(() => {
        const handleInvalidToken = () => {
            AuthService.isUserAuthenticated()
                .then((isUserLogged) => {
                    setState(isUserLogged ? 'loggedin' : 'redirect')
                })
                .catch(() => setState('redirect'))
        }
        window.addEventListener('storage', handleInvalidToken)
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
    }, [])

    useEffect(() => {
        ;(async function () {
            try {
                const isUserLogged = await AuthService.isUserAuthenticated()
                setState(isUserLogged ? 'loggedin' : 'redirect')
            } catch {
                setState('redirect')
            }
        })()
    }, [])

    if (state === 'loading') {
        return (
            <div className="pt-3 text-center">
                <div className="sk-spinner sk-spinner-pulse"></div>
            </div>
        )
    }
    return (
        <Route
            {...rest}
            render={() => {
                if (state === 'loggedin') {
                    return <Component />
                } else {
                    return <Redirect to={'/login'} />
                }
            }}
        />
    )
}

export default ProtectedRoute
