export const logo = ['596.736 74.619', `
<title>EnduroSat</title>
<?xml version="1.0" encoding="utf-8"?>
<svg viewBox="90.105 0 458.762 74.619" xmlns="http://www.w3.org/2000/svg">
  <path d="M 144.874 30.918 L 148.894 39.798 L 166.861 16.828 L 162.894 7.946 L 144.874 30.918" fill="#253e7a" style=""/>
  <path d="M 149.719 64.22 L 119.5 61.641 L 115.693 53.203 L 145.97 55.784 L 149.719 64.221 M 108.324 36.961 L 112.163 45.379 L 142.347 47.968 L 138.585 39.545 L 108.324 36.961" fill="#3e89d0" style=""/>
  <path d="M 145.97 55.784 L 149.719 64.221 L 168.537 40.784 L 164.496 31.995 L 145.97 55.784" fill="#253e7a" style=""/>
  <g transform="matrix(0.496797, 0, 0, 0.496797, 195.32579, -320.075012)" style="">
    <path d="M1.693 754.732v-72.82h47.7v8.08h-38.65v23.473h37.883v8.077H10.754v25.1h38.65v8.082h-47.7m119.108-.002l-42.47-58.083v58.083H69.28v-72.82h9.28l41.924 56.88v-56.88h9.06v72.82H120.8m84.1-36.356c0-15.722-9.933-28.385-27.948-28.385h-15.83v56.66h15.83c17.687 0 27.948-12.663 27.948-28.274zm-52.84 36.356v-72.82h24.892c22.6 0 37.337 15.83 37.337 36.465 0 20.744-14.737 36.356-37.337 36.356H152.05m81.896-28.278V681.9h9.06v44.325c0 13.32 7.207 21.727 20.633 21.727s20.635-8.406 20.635-21.727V681.9h9.063v44.434c0 18.123-9.936 29.695-29.697 29.695s-29.695-11.68-29.695-29.585m124.582-22.598c0-8.2-5.896-13.866-14.52-13.866h-19.105v27.84h19.105c8.624 0 14.52-5.788 14.52-13.974zm-.545 50.876l-18.56-28.933h-14.52v28.933h-9.06v-72.82h29.26c13.32 0 22.817 8.516 22.817 21.945 0 13.1-8.952 20.305-18.888 21.18l19.653 29.696h-10.7m89.643-36.357c0-17.03-10.48-29.588-26.748-29.588-16.376 0-26.75 12.557-26.75 29.588 0 16.92 10.373 29.587 26.75 29.587 16.267 0 26.748-12.666 26.748-29.587zm-62.887 0c0-21.508 14.52-37.665 36.14-37.665 21.508 0 36.137 16.157 36.137 37.665s-14.63 37.664-36.137 37.664c-21.617 0-36.14-16.157-36.14-37.664m87.575 26.092l5.35-6.88c4.804 5.35 12.665 10.373 22.928 10.373 13 0 17.14-6.987 17.14-12.556 0-18.78-43.125-8.405-43.125-34.4 0-12 10.7-20.197 25.003-20.197 11.135 0 19.76 3.712 25.875 10.15l-5.46 6.663c-5.456-6.007-13.1-8.737-21.067-8.737-8.736 0-14.96 4.695-14.96 11.573 0 16.376 43.125 6.987 43.125 34.173 0 10.6-7.204 21.396-26.857 21.396-12.665 0-21.945-4.8-27.95-11.572M572.44 691.3l-15.5 39.193h30.897zm24.567 63.432l-6.44-16.16h-36.25l-6.44 16.16h-10.373l29.26-72.82h11.244l29.368 72.82h-10.37m36.042 0v-64.74h-23.036v-8.08h55.243v8.08H642.1v64.74h-9.06" fill="#3e89d0"/>
  </g>
</svg>
`]
