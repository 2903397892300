import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilViewQuilt,
    cilCalendar,
    cilInfo,
    cilBookmark,
    cilSettings,
    cilCamera,
    cilPaperPlane,
    cilLayers,
    cilBattery3,
    cilCalculator,
    cilGrid,
    cilCursorMove,
    cilAudio,
    cilRss,
    cilSearch,
    cilCheck,
    cilBraille,
    cilCommand,
    cilUser,
    cilAccountLogout,
    cilSatelite,
} from '@coreui/icons/js/free'

import { cilLibraryBooks, cilQueueRemove } from '@coreui/icons-pro/js/index'

import { cibAtom } from '@coreui/icons/js/brand'

export const icons = Object.assign(
    {},
    {
        sygnet,
        logo,
        logoNegative,
    },
    {
        cilQueueRemove,
        cilApplicationsSettings,
        cilSpeedometer,
        cilSun,
        cilMoon,
        cilViewQuilt,
        cilCalendar,
        cilInfo,
        cilBookmark,
        cilSettings,
        cilCamera,
        cilPaperPlane,
        cilLayers,
        cilBattery3,
        cilCalculator,
        cilGrid,
        cilCursorMove,
        cilAudio,
        cilRss,
        cilSearch,
        cilCheck,
        cilBraille,
        cilCommand,
        cilAccountLogout,
        cilUser,
        cilSatelite,
        cibAtom,
        cilLibraryBooks,
    }
)
