import AuthLocalStorageService from './local-storage-services/authentication-local-storage.service'
import { decodeToken } from 'react-jwt'
import envGetter from '../utility/env-variables-getter'

import { v4 as uuidv4 } from 'uuid'
import { authenticationAPI } from './endurosat-api.service'
import { AuthenticationResponse } from '../gen/endurosat-api'
import axios from 'types-axios'
import { AuthenticationData } from '../models/authentication/authentication-data.model'

class AuthenticationService {
    private static instance: AuthenticationService

    public static getInstance: () => AuthenticationService = () => {
        if (!AuthenticationService.instance) {
            AuthenticationService.instance = new AuthenticationService()
        }
        return AuthenticationService.instance
    }

    public isSharedSatUser = () => {
        const idToken = AuthLocalStorageService.getAuthData()?.id_token

        if (!idToken) {
            return false
        }

        const data = decodeToken(idToken)

        return data['cognito:groups'] &&
            data['cognito:groups'].find(
                (group: string) => group === 'SHARED_SAT'
            )
            ? true
            : false
    }

    getAuthUrl = () => {
        const {
            oauthBaseUrl,
            oauthResponseType,
            oauthClientId,
            oauthRedirectUri,
            oauthIdentityProvider,
            oauthScope,
        } = envGetter

        //saves the link from where the call was made in order to redirect to that page after the authentication

        const authUrl = `${oauthBaseUrl}/authorize?response_type=${oauthResponseType}&client_id=${oauthClientId}&redirect_uri=${oauthRedirectUri}&identity_provider=${oauthIdentityProvider}&scope=${oauthScope}`

        return authUrl
    }

    getAuthIdentifier = () => {
        const authIdentifier = uuidv4()

        return authIdentifier
    }

    exchangeCodeForTokens = (code: string): Promise<AuthenticationResponse> => {
        const {
            oauthBaseUrl,
            oauthGrantType,
            oauthClientId,
            oauthRedirectUri,
        } = envGetter

        const params = new URLSearchParams()
        params.append('redirect_uri', oauthRedirectUri)
        params.append('grant_type', oauthGrantType)
        params.append('client_id', oauthClientId)
        params.append('code', code)

        return axios
            .post(`${oauthBaseUrl}/oauth2/token`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: '*/*',
                },
            })
            .then((response) => {
                if (response.data) {
                    return Promise.resolve(response.data)
                }
                return Promise.reject('No valid token response')
            })
            .catch((error) => {
                this.invalidateTokens()
                return Promise.reject(error)
            })
    }

    getUserInfoFromRequest = (): Promise<AuthenticationData> => {
        const { oauthBaseUrl } = envGetter

        const accessToken = AuthLocalStorageService.getAuthData()?.access_token

        if (!accessToken) {
            return Promise.reject('No valid access token in the storage')
        }

        return axios
            .get(`${oauthBaseUrl}/oauth2/userInfo`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })
            .then((response) => {
                if (response.data) {
                    return Promise.resolve(response.data)
                }
                return Promise.reject('No valid token response')
            })
            .catch((error) => {
                this.invalidateTokens()
                return Promise.reject(error)
            })
    }

    getUserInfoFromAuthIdToken = (): Promise<AuthenticationData> => {
        return new Promise((resolve, reject) => {
            const idToken = AuthLocalStorageService.getAuthData()?.id_token

            if (!idToken) {
                return reject('No valid id token in the storage')
            }

            const data = decodeToken(idToken)

            resolve(data)
        })
    }

    refreshTokens = (refreshToken: string): Promise<AuthenticationResponse> => {
        const { oauthClientId } = envGetter

        return authenticationAPI
            .authenticate(
                oauthClientId,
                `refresh_token`,
                undefined,
                undefined,
                refreshToken
            )
            .then((response) => {
                if (response.data) {
                    return Promise.resolve(response.data)
                }
                return Promise.reject('No valid token response')
            })
            .catch((error) => {
                this.invalidateTokens()
                return Promise.reject(error)
            })
    }

    retrieveValidAuthData = (): Promise<AuthenticationResponse> => {
        return new Promise((resolve, reject) => {
            const authData = AuthLocalStorageService.getAuthData()

            if (!authData) {
                return reject('No valid authentication data in the storage')
            }

            if (this.isAuthAccessTokenExpired(authData.access_token!)) {
                if (!authData.refresh_token) {
                    return reject('No valid refresh token in the storage')
                }
                this.refreshTokens(authData.refresh_token)
                    .then((data) => {
                        this.storeAuthTokens({
                            ...data,
                            refresh_token: authData.refresh_token,
                        })
                        resolve({
                            access_token: data.access_token,
                            expires_in: data.expires_in,
                            id_token: data.id_token,
                        })
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                resolve({
                    access_token: authData.access_token,
                    expires_in: authData.expires_in,
                    id_token: authData.id_token,
                })
            }
        })
    }

    invalidateTokens = () => {
        AuthLocalStorageService.removeAuthData()
    }

    storeAuthTokens = (data: AuthenticationResponse) => {
        AuthLocalStorageService.setAuthData(data)
    }

    isAuthAccessTokenExpired = (accessToken: string) => {
        const { exp: expirationTime } = decodeToken(accessToken)

        if (expirationTime) {
            return Date.now() / 1000 > expirationTime
        }

        return true
    }

    isUserAuthenticated = (): Promise<boolean> => {
        return this.retrieveValidAuthData()
            .then(() => Promise.resolve(true))
            .catch(() => {
                return Promise.resolve(false)
            })
    }
}

export default AuthenticationService.getInstance()
