import dotenv from 'dotenv'

dotenv.config({ path: `.env.{${process.env.NODE_ENV}}` })

const EnvVariablesGetterUtility = {
    oauthResponseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE || '',
    oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID || '',
    oauthRedirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI || '',
    oauthBaseUrl: process.env.REACT_APP_OAUTH_BASE_LINK || '',
    oauthGrantType: process.env.REACT_APP_OAUTH_GRANT_TYPE || '',
    oauthIdentityProvider: process.env.REACT_APP_OAUTH_IDENTITY_PROVIDER || '',
    oauthScope: process.env.REACT_APP_OAUTH_SCOPE || '',
    useLocal: process.env.REACT_APP_USE_LOCAL_API,
    groundStationApiUri:
        process.env.REACT_APP_USE_LOCAL_API === 'true'
            ? process.env.REACT_APP_LOCAL_GROUND_STATION_URI || ''
            : process.env.REACT_APP_GROUND_STATION_URI || '',
    baseUrl: process.env.REACT_APP_BASE_URL || '',
    webSocketAuthenticationUri:
        process.env.REACT_APP_WEB_SOCKET_AUTHENTICATION_URI,
    localForageApiCacheName:
        process.env.REACT_APP_LOCALFORAGE_API_CACHE_NAME || '',
}

export default EnvVariablesGetterUtility
