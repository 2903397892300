import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './container-componenets/protected-route/ProtectedRoute'
import './scss/style.scss'
const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

global.Buffer = Buffer

// Containers
const LogoutHandler = React.lazy(
    () => import('./container-componenets/logout-handler/LogoutHandler')
)
const TheLayout = React.lazy(
    () => import('./presentational-components/layout/layout/TheLayout')
)
const LoginComponent = React.lazy(
    () => import('./presentational-components/login/LoginComponent')
)
const LoginRedirectComponent = React.lazy(
    () => import('./presentational-components/login/LoginRedirectComponent')
)

const App = () => {
    return (
        <BrowserRouter>
            <React.Suspense fallback={loading}>
                <Switch>
                    <Route exact path="/login" component={LoginComponent} />
                    <Route
                        exact
                        path="/login-redirect"
                        component={LoginRedirectComponent}
                    />
                    <Route path="/logout" component={LogoutHandler} />
                    <ProtectedRoute path="/" component={TheLayout} />
                </Switch>
            </React.Suspense>
        </BrowserRouter>
    )
}
export default App
