import axios, { AxiosRequestConfig } from 'axios'
import envGetter from '../utility/env-variables-getter'
import AuthService from '../services/authentication.service'
import { AuthenticationResponse } from '../gen/endurosat-api'

export const registerAxiosAuthHeaders = (
    groundStationUri: string,
    getAuthData: () => Promise<AuthenticationResponse>
) => {
    axios.interceptors.request.use((request) => {
        return addAuthHeader(request, groundStationUri, getAuthData)
    })
}

export const addAuthHeader = (
    request: AxiosRequestConfig,
    groundStationApiUri: string,
    getAuthData: () => Promise<AuthenticationResponse>
) => {
    if (request.url?.includes(groundStationApiUri)) {
        return getAuthData()
            .then((authData) => {
                request.headers = {
                    ...request.headers,
                    Authorization: `Bearer ${authData.access_token}`,
                    'Content-Type': 'application/json',
                }
                return request
            })
            .catch(() => Promise.resolve(request))
    }
    return Promise.resolve(request)
}

const registerAxiosAuthHeadersDefault = () =>
    registerAxiosAuthHeaders(
        envGetter.groundStationApiUri,
        AuthService.retrieveValidAuthData
    )

export default registerAxiosAuthHeadersDefault
