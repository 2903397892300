import {createSlice} from '@reduxjs/toolkit'
interface State {
    isOpen: boolean,
    text: string,
    onConfirm: () => void,

}

const initialState: State = {
    isOpen: false,
    text: 'Are you sure you want to do this?',
    onConfirm: () => {},
};

export const confirmationModalSlice = createSlice({
    name: 'message',
    initialState,

    reducers: {
        showConfirmationModal: (state: State, action) => {
            state.isOpen = true;
            state.text = action.payload.text;
            state.onConfirm = action.payload.onConfirm;
        },
        closeConfirmationModal: (state: State) => {
            state.isOpen = false;
        },
    },

})

export const {showConfirmationModal, closeConfirmationModal} = confirmationModalSlice.actions;

export default confirmationModalSlice.reducer
